import { ax } from "services/base";

import { axPlacesAutoComplete } from "services/base";

import { axSRCMStaticData, axStaticFileData } from "services/base";

// utils
import { getLoginUserRole } from "utils/login";

import { generalStatuses } from "utils/constants";

// constants
import config from "assets/config";

class DropdownService {
  getCountryList() {
    let params = {
      pageNumber: 0,
      pageSize: 250,
      countryStatus: "ACTIVE",
    };

    return ax.get("countries", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.country || [],
      },
    }));
  }

  getBookNumbers(inputData) {
    return ax.post("book-numbers", inputData).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.bookNumber || [],
      },
    }));
  }

  getOrganizationList() {
    let params = {
      pageNumber: 0,
      pageSize: 20000,
      status: "ACTIVE",
    };

    return ax.get("organizations", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.organization || [],
      },
    }));
  }

  getCountryOrganizationList(payload) {
    return ax.post("organizations-by-countryids", payload).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.organization || [],
      },
    }));
  }

  getCenterList(payload) {
    let params = {
      pageNumber: 0,
      pageSize: 20000,
      centerStatus: "ACTIVE",
      ...payload,
    };

    return ax.get("centers", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.center || [],
      },
    }));
  }

  getDonationCampaignList() {
    let params = {
      pageNumber: 0,
      pageSize: 20000,
      status: generalStatuses.active.value,
    };

    return ax.get("donation-campaign", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.donationCampaign || [],
      },
    }));
  }

  getOrgCampaignList(payload) {
    let params = {
      pageNumber: 0,
      pageSize: 20000,
      orgId: getLoginUserRole().organization?.id,
      status: generalStatuses.active.value,
      ...payload,
    };

    return ax.get("donation-campaign", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.donationCampaign || [],
      },
    }));
  }

  getRoleList() {
    let params = {
      pageNumber: 0,
      pageSize: 200,
      roleStatus: "ACTIVE",
    };

    return ax.get("roles", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.roles || [],
      },
    }));
  }

  getAccounts(params) {
    return ax.get("bank/account/details", { params }).then((res) => ({
      data: {
        count: res.data?.bankAccountDetails?.length || 0,
        results: res.data?.bankAccountDetails || [],
      },
    }));
  }

  getUserList(payload) {
    let params = {
      pageSize: 200,
      ...payload,
      pageNumber: 0,
    };

    return ax.get("admin-users", { params }).then((res) => ({
      data: {
        count: res.data.count || 0,
        results: res.data.adminUser || [],
      },
    }));
  }

  getTimeZonesLists() {
    let params = {
      pageSize: 2000,
      pageNumber: 0,
    };

    return ax.get("time-zones", { params }).then((res) => ({
      data: {
        count: res.data.timeZoneConfigs.length || 0,
        results: res.data.timeZoneConfigs || [],
      },
    }));
  }

  getCurrencyConversions(payload = "INR") {
    return axSRCMStaticData.get(`rates/${payload}.json`);
  }

  getCachedCitySuggestions(payload) {
    return axSRCMStaticData.get(`srcmapi/cities/${payload}.json`);
  }

  getCachedStateSuggestions(payload) {
    return axSRCMStaticData.get(`srcmapi/states/${payload}.json`);
  }

  getCachedCountrySuggestions() {
    return axSRCMStaticData.get(`srcmapi/countries/all.json`);
  }

  getStaticFileData() {
    return axStaticFileData.get(`conf/dms/config.json`);
  }

  getGPlaceSuggestions(payload) {
    return axPlacesAutoComplete.get("gplaces", {
      params: {
        session: config.gPlacesSession,
        input: payload,
      },
    });
  }
}

export default DropdownService;
