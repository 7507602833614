// axios
import axios from "axios";

// request and response interceptor
import interceptor from "services/baseInterceptor";

// config
import config from "assets/config";

export const ax = axios.create({
  baseURL: config.apiURI
});

export const axApp = axios.create({
  baseURL: config.appURI
});

export const axPlacesAutoComplete = axios.create({
  baseURL: config.placesAutoCompleteURI
});

export const axSRCMStaticData = axios.create({
  baseURL: config.srcmStaticDataURI
});

export const axStaticFileData = axios.create({
  baseURL: config.staticFileDataURI,
});

interceptor(ax);