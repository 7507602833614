import React, { useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";

// firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// components
import AuthGuard from "auth-guard";

import Login from "components/login/Login";

import AbhaysForm from "components/abhyasiGeneralInfoForm/abhyasi-donors-general-information";

// shared components
import HFNErrorBoundary from "components/errorBoundary";

import HFNLoader from "sharedComponents/lazyLoading";

import HFNToaster from "sharedComponents/toasters";

import HFNConfirmDialog from "sharedComponents/confirmDialog";

import TokenExpire from "components/logout/tokenExpire";

// utils
import lazy from "utils/lazy";

import { setFirebaseToken, setFirebaseUser } from "utils/login";

// config
import config from "assets/config";
import { isWebsiteUnderMaintenance } from "utils/maintenance";

// lazy components
const LayoutContainer = lazy("layouts/Template");

const DepositDonationSummary = lazy("donation/depositDonation/Summary");

const MobileThermalView = lazy("donation/donationEntry/MobileThermalView");

const App = () => {
  const [underMaintenance, setUnderMaintenance] = useState(false);

  useEffect(() => {
    isWebsiteUnderMaintenance().then((res) => setUnderMaintenance(res));
  }, []);

  useEffect(() => {
    if (underMaintenance) {
      import("hfn-maintenance-web/main");
    }
  }, [underMaintenance]);

  useEffect(() => {
    // let refreshTokenFunc = null;
    if (config?.firebaseAuthConfig) {
      if (!firebase.apps.length)
        firebase.initializeApp(JSON.parse(config.firebaseAuthConfig), "auth");
      firebase
        .app("auth")
        ?.auth()
        ?.onAuthStateChanged((firebaseUser) => {
          if (firebaseUser) {
            setFirebaseUser(firebaseUser);
          }
        });
      firebase
        .app("auth")
        ?.auth()
        ?.onIdTokenChanged((firebaseUser) => {
          firebaseUser?.getIdToken().then((srcmToken) => {
            setFirebaseToken(srcmToken);
          });
        });
    }
  }, []);
  return (
    <HFNErrorBoundary>
      <HFNLoader>
        <div className="hfn">
          {underMaintenance ? (
            <hfn-maintenance-web />
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/abhyasi-donors-general-information"
                element={<AbhaysForm />}
              />
              <Route
                path="*"
                element={<AuthGuard path="/" component={LayoutContainer} />}
              />

              <Route
                path="/deposit-donation-summary"
                element={
                  <AuthGuard
                    path="/deposit-donation-summary"
                    component={DepositDonationSummary}
                  />
                }
              />

              <Route
                path="/mobile-thermal-view"
                element={
                  <AuthGuard
                    path="/mobile-thermal-view"
                    component={MobileThermalView}
                  />
                }
              />
            </Routes>
          )}
        </div>

        <div>
          <HFNToaster />
          <HFNConfirmDialog />
          <TokenExpire />
        </div>
      </HFNLoader>
    </HFNErrorBoundary>
  );
};

export default App;
