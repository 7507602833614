import React from "react";

function Header() {
  return (
    <div className="abhyasi-header">
      <div>
        <img
          src="https://cdn-prod.heartfulness.org/images/heartfulness-advancing-in-love-logo-blue.png"
          alt="heartfulness"
          style={{ height: "53px" }}
        />
      </div>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div style={{ marginTop: "13px" }}>Abhyasi General Information</div>
    </div>
  );
}

export default Header;
