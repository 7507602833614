// eslint-disable-next-line no-undef
const configData = process.env;
let config = {};

try {
  config.apiURI =
    configData.REACT_APP_API_BASE_URI ||
    "https://donation-management-service-grpc.qa.heartfulnessinstitute.in/";
  config.NODE_ENV = configData.NODE_ENV;
  config.placesAutoCompleteURI =
    configData.REACT_APP_PLACES_AUTOCOMPLETE_URI ||
    "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURI =
    configData.REACT_APP_STATIC_DATA_SRCM_URI ||
    "https://static-gatsby-qa.web.app/";
  config.firebaseAuthConfig = configData.REACT_APP_FIREBASE_AUTH_CONFIG;
  config.siteKey = configData.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
  config.staticFileDataURI =
    configData.REACT_APP_STATIC_FILE_DATA_URI ||
    "https://static-file-staging.web.app/";
} catch {
  config.apiURI =
    "https://donation-management-service-grpc.qa.heartfulnessinstitute.in";
  config.placesAutoCompleteURI =
    "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
  config.srcmStaticDataURI = "https://static-gatsby-qa.web.app/";
  config.staticFileDataURI = "https://static-file-staging.web.app/";
}

config.appURI = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

config.gPlacesSession = "74c576ef-7234-4f47-8b11-f8e41d247f3b";

config.maxAllowedFileSize = 20;

export default config;
