import { PROP, MERGE, CLEAR, REF } from "store/actionTypes/toaster";

import { RESET } from "store/actionTypes/login";

const initialState = {
  ref: { current: null },
  props: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return { ...state, props: null };
    case PROP:
      return { ...state, props: payload };
    case MERGE:
      return { ...state, props: Object.assign({}, state.props, payload) };
    case CLEAR:
      return { ...state, props: null };
    case REF:
      return { ...state, ref: payload };
    default:
      return state;
  }
};

export default reducer;
