// local storage
import { lStorage } from "utils/storage";

// utils
import { getFirebaseUser, logout } from "utils/login";

import toaster from "utils/toaster";

const interceptor = ax => {
  ax.interceptors.request.use(
    async config => {
      let authInfo = lStorage.get("authInfo");

      if (authInfo?.srcmToken) {
        const firebaseUser = getFirebaseUser();
        let firebaseToken = null;
        try {
          firebaseToken = await firebaseUser?.getIdToken?.();
        }
        catch { console.log("Something went wrong"); }
        config.headers["Authorization"] = `Bearer ${firebaseToken || authInfo.srcmToken}`;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  ax.interceptors.response.use(
    next => Promise.resolve(next),
    error => {
      if (error.response && error.response.status === 401)
        logout();
      else
        toaster.custom({
          severity: "error",
          summary: "Error:",
          detail: error?.response?.data?.message || error.message
        });

      return Promise.reject(error);
    }
  );
};

export default interceptor;
