import React from "react";

const HFNLoading = () => {
  return (
    <div className="lds-roller-wrapper">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default HFNLoading;
