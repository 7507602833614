import { TOGGLE, ONHIDE, ONSHOW, CUSTOM } from "store/actionTypes/modalPopup";

import { RESET } from "store/actionTypes/login";

// constants
const initialState = {
  header: "ModalPopup",
  visible: false,
  className: "sdm-popup",
  blockScroll: true,
  onHide: () => { }
};

// reducer
const modalPopupDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...state, ...initialState };
    case TOGGLE:
      return { ...state, visible: action.payload, footer: null };
    case ONSHOW:
      return { ...state, onShow: () => { action.payload() } };
    case ONHIDE:
      return { ...state, onHide: () => { action.payload() } };
    case CUSTOM:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default modalPopupDetails;
