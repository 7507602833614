import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";

// state
import { useSelector } from "react-redux";

// form
import { useForm, Controller } from "react-hook-form";

// components
import Select from "react-select";

import Async from "react-select/async";

import PhoneInput from "react-phone-input-2";

//prime components
import { InputText } from "primereact/inputtext";

import { Password } from "primereact/password";

import { InputTextarea } from "primereact/inputtextarea";

import { Checkbox } from "primereact/checkbox";

import { InputSwitch } from "primereact/inputswitch";

import { Slider } from "primereact/slider";

import { Calendar } from "primereact/calendar";

import { Chips } from "primereact/chips";

import { Button } from "primereact/button";

import { ToWords } from "to-words";

// utils
import { isEmpty, isEqual } from "lodash";

import { classNames } from "primereact/utils";

import modalPopup from "utils/modalPopup";

import {
  getCitySuggestions,
  getSateCitySuggestions,
  getStateSuggestions,
  getCountrySuggestions,
} from "utils/autoComplete";

import { cityACTemplate } from "utils/badgeTemplate";

const HFNDynamicForm = forwardRef(
  (
    {
      initialValues = {},
      fields = {},
      onFormSubmit,
      submitButtonGroup,
      formConfig,
      visibilityCheck,
      disabilityCheck,
      useFormInit = {},
      resetAmount,
    },
    ref
  ) => {
    const toWords = new ToWords({
      localeCode: "en-IN",
      converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
      },
    });

    const [amountInWord, setAmountInWord] = useState(null);

    const [visibles, setVisibles] = useState(() => ({
      ...Object.fromEntries(Object.keys(fields).map((key) => [key, true])),
      ...(typeof visibilityCheck === "function"
        ? visibilityCheck(initialValues)
        : null),
    }));

    const [disables, setDisables] = useState(() => ({
      ...Object.fromEntries(Object.keys(fields).map((key) => [key, false])),
      ...(typeof disabilityCheck === "function"
        ? disabilityCheck(initialValues)
        : null),
    }));

    const [selectOptions, setSelectOptions] = useState(() =>
      Object.fromEntries(
        Object.entries(fields)
          .filter(([, field]) =>
            field?.properties?.primeFieldProps?.options ? true : false
          )
          .map(([key, field]) => [
            key,
            field.properties.primeFieldProps.options,
          ])
      )
    );

    const dd = useSelector((state) => state.dropdownDetails);

    const {
      watch,
      handleSubmit,
      formState: { errors, isSubmitted },
      control,
      register,
      setValue,
      getValues,
      resetField,
      trigger,
      setError,
      reset,
      clearErrors,
    } = useForm({
      mode: "onChange",
      ...useFormInit,
    });

    useEffect(() => {
      let subscription = null;
      if (
        (typeof visibilityCheck === "function" ||
          typeof disabilityCheck === "function") &&
        subscription === null
      )
        subscription = watch((value, { name, type }) => {
          if (typeof visibilityCheck === "function") {
            const visibilityChecks = visibilityCheck(value, name, type);
            if (visibilityChecks) {
              const newVisibles = { ...visibles, ...visibilityChecks };
              if (!isEqual(visibles, newVisibles)) setVisibles(newVisibles);
            }
          }
          if (typeof disabilityCheck === "function") {
            const disabilityChecks = disabilityCheck(value, name, type);
            if (disabilityChecks) {
              const newDisables = { ...disables, ...disabilityChecks };
              if (!isEqual(disables, newDisables)) setDisables(newDisables);
            }
          }
        });
      if (resetAmount === true) {
        setAmountInWord("");
        resetAmount = false;
      }
      return () => subscription?.unsubscribe?.();
    }, [watch, visibles, disables, resetAmount]);

    const closeModel = useMemo(
      () => (
        <div className="form-button-group">
          <Button
            type="button"
            className="p-button p-button-secondary p-mr-2"
            label="Cancel"
            onClick={() => {
              modalPopup.toggle(false);
            }}
          />
          <Button
            type="submit"
            label="Submit"
            className="p-button p-button-primary"
          />
        </div>
      ),
      []
    );

    const setOptions = useCallback(
      (options, disabilityChecks, visibilityChecks) => {
        if (!isEmpty(options))
          setSelectOptions({ ...selectOptions, ...options });
        if (visibilityChecks) {
          const newVisibles = { ...visibles, ...visibilityChecks };
          if (!isEqual(visibles, newVisibles)) setVisibles(newVisibles);
        }
        if (disabilityChecks) {
          const newDisables = { ...disables, ...disabilityChecks };
          if (!isEqual(disables, newDisables)) setDisables(newDisables);
        }
      },
      [selectOptions, disables, visibles]
    );

    useImperativeHandle(
      ref,
      () => ({
        setOptions: setOptions,
      }),
      [setOptions]
    );

    const loadACOptions = useCallback(
      (inputValue, callback, service, method, labelField, valueField) => {
        try {
          if (inputValue.length > 2) {
            service[method](inputValue).then((res) => {
              let acOptions = [];
              let results = [];

              if (res && res.data) {
                if (Array.isArray(res.data)) results = res.data;
                else if (Array.isArray(res.data.data)) results = res.data.data;

                acOptions = results.map((acOption) => ({
                  ...acOption,
                  label: acOption[labelField],
                  value: acOption[valueField],
                }));
              }
              callback(acOptions);
            });
          }
        } catch {
          callback([]);
          console.log("Something went wrong.");
        }
      },
      []
    );

    const onSubmitForm = useCallback(
      (data, e) => {
        onFormSubmit(data, setError, e, visibles, reset, setValue);
      },
      [visibles]
    );

    return (
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className={`form-wrapper ${formConfig ? formConfig.formClassName : ""}`}
        autoComplete={`${formConfig ? formConfig.autoComplete : "on"}`}
      >
        <div
          className={`p-fluid form-section p-d-flex p-flex-wrap ${
            formConfig ? formConfig.formSectionClassName : ""
          }`}
        >
          {Object.keys(fields).map((key, index) => {
            const { properties } = fields[key];

            let primeFieldProps = {},
              validations;

            if (properties.primeFieldProps)
              primeFieldProps = properties.primeFieldProps;

            if (properties.validations) {
              if (typeof properties.validations.validate === "function") {
                const validate = (value) =>
                  properties.validations.validate(
                    value,
                    getValues,
                    setError,
                    clearErrors
                  );
                validations = { ...properties.validations, validate };
              } else validations = properties.validations;
            } else validations = {};

            return properties.visibility === false ||
              visibles[key] === false ? (
              <React.Fragment key={key}></React.Fragment>
            ) : (
              <React.Fragment key={key}>
                <div
                  className={`p-field-wrapper p-col-12 ${
                    properties.fieldWrapperClassNames
                      ? properties.fieldWrapperClassNames
                      : ""
                  }`}
                  key={key + index}
                >
                  {["Checkbox"].indexOf(properties.type) === -1 ? (
                    <label
                      htmlFor="lastname1"
                      className={`p-field-label ${
                        properties.fieldLabelClassNames
                          ? properties.fieldLabelClassNames
                          : ""
                      }`}
                    >
                      {validations &&
                      validations.required &&
                      validations.required.value ? (
                        <em>*&nbsp;</em>
                      ) : (
                        <></>
                      )}
                      {properties.label}
                      {properties.tooltip ? (
                        <span
                          className="tooltip-icon"
                          title={properties.tooltip}
                        >
                          {" "}
                          <i className="uil uil-info-circle"></i>{" "}
                        </span>
                      ) : (
                        <></>
                      )}
                    </label>
                  ) : null}

                  <div
                    className={`p-field-section ${
                      properties.fieldSectionClassNames
                        ? properties.fieldSectionClassNames
                        : ""
                    }`}
                  >
                    {(() => {
                      switch (properties.type) {
                        case "InputText":
                          return (
                            <div>
                              <Controller
                                control={control}
                                rules={validations}
                                name={key}
                                defaultValue={initialValues[key] || ""}
                                render={({
                                  field: { onChange, onBlur, value, name },
                                }) => {
                                  return (
                                    <InputText
                                      {...primeFieldProps}
                                      className={classNames({
                                        "p-invalid": errors[key],
                                      })}
                                      name={name}
                                      value={value}
                                      disabled={
                                        primeFieldProps.disabled ||
                                        disables[key]
                                      }
                                      onChange={(e) => {
                                        onChange(e.target.value, getValues);
                                        if (
                                          primeFieldProps.displayamountinword ===
                                          true
                                        ) {
                                          const selectedValues =
                                            getValues("currency").value;
                                          if (e?.target?.value !== "") {
                                            setAmountInWord(
                                              selectedValues.concat(
                                                " ",
                                                toWords.convert(
                                                  e?.target?.value
                                                )
                                              )
                                            );
                                          } else {
                                            setAmountInWord("");
                                          }
                                        }
                                        if (
                                          primeFieldProps &&
                                          primeFieldProps.onChange &&
                                          typeof primeFieldProps.onChange ===
                                            "function"
                                        )
                                          primeFieldProps.onChange(
                                            e?.target?.value,
                                            setValue,
                                            isSubmitted,
                                            getValues,
                                            errors,
                                            resetField,
                                            trigger,
                                            setError,
                                            e?.target?.selectionStart,
                                            e?.target
                                          );
                                      }}
                                      onBlur={(e) => {
                                        onBlur(e);
                                        primeFieldProps?.onBlur?.(
                                          e?.target?.value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError,
                                          clearErrors
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                              {primeFieldProps.displayamountinword && (
                                <div className="amount-in-word">
                                  {amountInWord}
                                </div>
                              )}
                            </div>
                          );

                        case "Password":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || ""}
                              render={({
                                field: { onChange, value, name },
                              }) => {
                                return (
                                  <Password
                                    {...primeFieldProps}
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    name={name}
                                    value={value}
                                    readOnly={disables[key]}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                  />
                                );
                              }}
                            />
                          );

                        case "InputTextarea":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || ""}
                              render={({
                                field: { onChange, value, name },
                              }) => {
                                return (
                                  <InputTextarea
                                    {...primeFieldProps}
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    name={name}
                                    value={value}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          e.target.value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                  />
                                );
                              }}
                            />
                          );

                        case "Select":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                              }) => {
                                return (
                                  <Select
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    {...primeFieldProps}
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    name={name}
                                    value={value}
                                    onChange={(value, action) => {
                                      onChange(value);
                                      if (
                                        name === "currency" &&
                                        getValues("amount") !== ""
                                      ) {
                                        setAmountInWord(
                                          value.value.concat(
                                            " ",
                                            toWords.convert(getValues("amount"))
                                          )
                                        );
                                      }
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError,
                                          action,
                                          clearErrors
                                        );
                                    }}
                                    onBlur={(e) => {
                                      onBlur(e);
                                      primeFieldProps?.onBlur?.(
                                        value,
                                        setValue,
                                        isSubmitted,
                                        getValues,
                                        errors,
                                        resetField,
                                        trigger,
                                        setError
                                      );
                                    }}
                                    options={
                                      !isEmpty(properties.dropdownOptions) &&
                                      Array.isArray(
                                        dd[properties.dropdownOptions]
                                      )
                                        ? dd[properties.dropdownOptions]
                                        : selectOptions[key] || []
                                    }
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "MultiSelect":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || []}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Select
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    closeMenuOnSelect={false}
                                    noOptionsMessage={() => "No data found"}
                                    {...primeFieldProps}
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    name={name}
                                    value={value}
                                    isMulti={true}
                                    onChange={(value, action) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError,
                                          action
                                        );
                                    }}
                                    options={
                                      !isEmpty(properties.dropdownOptions) &&
                                      Array.isArray(
                                        dd[properties.dropdownOptions]
                                      )
                                        ? dd[properties.dropdownOptions]
                                        : selectOptions[key] || []
                                    }
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "AutoComplete":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Async
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    {...primeFieldProps}
                                    name={name}
                                    value={value}
                                    autoComplete="off"
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    loadOptions={(inputValue, callback) => {
                                      loadACOptions(
                                        inputValue,
                                        callback,
                                        properties.service,
                                        properties.method,
                                        primeFieldProps.optionLabel || "name",
                                        primeFieldProps.optionValue || "id"
                                      );
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "CityAutoComplete":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Async
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    optionLabel="name"
                                    optionValue="id"
                                    formatOptionLabel={cityACTemplate}
                                    {...primeFieldProps}
                                    name={name}
                                    value={value}
                                    autoComplete="off"
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    // loadOptions={getCitySuggestions}
                                    loadOptions={(inputValue, callback) => {
                                      getCitySuggestions(
                                        inputValue,
                                        callback,
                                        getValues("country")
                                      );
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "StateCityAutoComplete":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Async
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    optionLabel="name"
                                    optionValue="id"
                                    formatOptionLabel={cityACTemplate}
                                    {...primeFieldProps}
                                    name={name}
                                    value={value}
                                    autoComplete="off"
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    // loadOptions={getCitySuggestions}
                                    loadOptions={(inputValue, callback) => {
                                      getSateCitySuggestions(
                                        inputValue,
                                        callback,
                                        getValues("country"),
                                        getValues("state")
                                      );
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "StateAutoComplete":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Async
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    optionLabel="name"
                                    optionValue="id"
                                    {...primeFieldProps}
                                    name={name}
                                    value={value}
                                    autoComplete="off"
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    // loadOptions={getStateSuggestions}
                                    loadOptions={(inputValue, callback) => {
                                      getStateSuggestions(
                                        inputValue,
                                        callback,
                                        getValues("country")
                                      );
                                    }}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "CountryAutoComplete":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => {
                                return (
                                  <Async
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    noOptionsMessage={() => "No data found"}
                                    optionLabel="name"
                                    optionValue="id"
                                    {...primeFieldProps}
                                    name={name}
                                    value={value}
                                    autoComplete="off"
                                    isDisabled={
                                      primeFieldProps.isDisabled ||
                                      disables[key]
                                    }
                                    loadOptions={getCountrySuggestions}
                                    onChange={(value) => {
                                      onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "Checkbox":
                          return (
                            <div>
                              <Controller
                                name={key}
                                control={control}
                                rules={validations}
                                defaultValue={initialValues[key] || false}
                                render={(props) => (
                                  <Checkbox
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    {...primeFieldProps}
                                    inputId={key}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={({ checked }) => {
                                      props.field.onChange(checked);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          checked,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    checked={props.field.value}
                                  />
                                )}
                              />

                              <label
                                htmlFor={key}
                                className={`p-pl-2 p-checkbox-label ${
                                  properties.fieldLabelClassNames
                                    ? properties.fieldLabelClassNames
                                    : ""
                                }`}
                                style={{ verticalAlign: "middle" }}
                              >
                                {validations &&
                                validations.required &&
                                validations.required.value ? (
                                  <em>*&nbsp;</em>
                                ) : (
                                  <></>
                                )}
                                {properties.label}
                                {properties.tooltip ? (
                                  <span
                                    className="tooltip-icon"
                                    title={properties.tooltip}
                                  >
                                    {" "}
                                    <i className="uil uil-info-circle"></i>{" "}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </label>
                            </div>
                          );

                        case "RadioButton":
                          if (properties.items) {
                            return properties.items.map((item) => {
                              return (
                                <div
                                  key={item.key}
                                  className="p-field-radiobutton"
                                >
                                  <Controller
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    name={key}
                                    control={control}
                                    rules={validations}
                                    defaultValue={initialValues[key]}
                                    render={(props) => {
                                      return (
                                        <input
                                          type="radio"
                                          id={item.key}
                                          value={item.key}
                                          name={key}
                                          {...primeFieldProps}
                                          readOnly={
                                            primeFieldProps.readOnly ||
                                            disables[key]
                                          }
                                          onClick={(e) => {
                                            props.field.onChange(
                                              e.target.value
                                            );
                                            if (
                                              primeFieldProps &&
                                              primeFieldProps.onChange &&
                                              typeof primeFieldProps.onChange ===
                                                "function"
                                            )
                                              primeFieldProps.onChange(
                                                e.target.value,
                                                setValue,
                                                isSubmitted,
                                                getValues,
                                                errors,
                                                resetField,
                                                trigger,
                                                setError
                                              );
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  <label htmlFor={item.key}>{item.name}</label>
                                </div>
                              );
                            });
                          }
                          break;

                        case "InputSwitch":
                          return (
                            <div>
                              <Controller
                                name={key}
                                control={control}
                                rules={validations}
                                defaultValue={initialValues[key] || false}
                                render={(props) => (
                                  <InputSwitch
                                    {...primeFieldProps}
                                    inputId={key}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={({ value }) => {
                                      props.field.onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      ) {
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                      }
                                    }}
                                    checked={props.field.value}
                                  />
                                )}
                              />
                            </div>
                          );

                        case "FileUpload":
                          return (
                            <InputText
                              className={classNames({
                                "p-invalid": errors[key],
                              })}
                              type="file"
                              {...primeFieldProps}
                              name={key}
                              {...register(key, validations)}
                            />
                          );

                        case "Slider":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={(props) => {
                                return (
                                  <Slider
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    {...primeFieldProps}
                                    name={props.field.name}
                                    value={props.field.value}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onSlideEnd={({ value }) => {
                                      props.field.onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={props.field.ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "Calendar":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || null}
                              render={(props) => {
                                return (
                                  <Calendar
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    {...primeFieldProps}
                                    name={props.field.name}
                                    value={props.field.value}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={({ value }) => {
                                      props.field.onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                    inputRef={props.field.ref}
                                  />
                                );
                              }}
                            />
                          );

                        case "Chips":
                          return (
                            <div>
                              <Controller
                                name={key}
                                control={control}
                                rules={validations}
                                defaultValue={initialValues[key]}
                                render={(props) => (
                                  <Chips
                                    className={classNames({
                                      "p-invalid": errors[key],
                                    })}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={({ value }) => {
                                      props.field.onChange(value);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          value,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError
                                        );
                                    }}
                                  />
                                )}
                              />
                              <label htmlFor={key} className="p-checkbox-label">
                                {properties.label}
                              </label>
                            </div>
                          );

                        case "PhoneInput":
                          return (
                            <Controller
                              control={control}
                              rules={validations}
                              name={key}
                              defaultValue={initialValues[key] || ""}
                              render={(props) => {
                                return (
                                  <PhoneInput
                                    className={classNames({
                                      "p-invalid": errors[key],
                                      "p-disabled":
                                        primeFieldProps.disabled ||
                                        disables[key],
                                    })}
                                    country="in"
                                    value={props.field.value}
                                    name={props.field.name}
                                    {...primeFieldProps}
                                    disabled={
                                      primeFieldProps.disabled || disables[key]
                                    }
                                    onChange={(
                                      value,
                                      country,
                                      e,
                                      formattedValue
                                    ) => {
                                      props.field.onChange(formattedValue);
                                      if (
                                        primeFieldProps &&
                                        primeFieldProps.onChange &&
                                        typeof primeFieldProps.onChange ===
                                          "function"
                                      )
                                        primeFieldProps.onChange(
                                          formattedValue,
                                          setValue,
                                          isSubmitted,
                                          getValues,
                                          errors,
                                          resetField,
                                          trigger,
                                          setError,
                                          country,
                                          e,
                                          value
                                        );
                                    }}
                                    inputRef={props.field.ref}
                                  />
                                );
                              }}
                            />
                          );

                        default:
                          return <>Form field not available</>;
                      }
                    })()}
                  </div>

                  <div
                    className={`p-error-section ${
                      properties.fieldErrorClassNames
                        ? properties.fieldErrorClassNames
                        : ""
                    }`}
                  >
                    {errors[key] && (
                      <span role="alert" className="error">
                        {" "}
                        {errors[key].message}{" "}
                      </span>
                    )}
                  </div>

                  {properties.hint ? (
                    <div
                      className={`p-hint-section ${
                        properties.fieldHintClassNames
                          ? properties.fieldHintClassNames
                          : ""
                      }`}
                    >
                      {properties.hint}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </div>

        {submitButtonGroup && typeof submitButtonGroup === "function"
          ? submitButtonGroup(handleSubmit)
          : closeModel}
      </form>
    );
  }
);

HFNDynamicForm.displayName = "HFNForm";

export default memo(HFNDynamicForm);
