import config from "assets/config";
import { MAINTENANCE_PROJECT_CODE } from "./constants";

export const isWebsiteUnderMaintenance = async () => {
  const projectCode = MAINTENANCE_PROJECT_CODE;
  const projectsURL = `${config.staticFileDataURI}conf/hfn-maintenance-web/projects.json`;
  let project = {};

  try {
    const response = await fetch(projectsURL);
    const projectsData = await response.json();
    project = projectsData.PROJECTS.find((p) => p.project_code === projectCode);
  } catch (e) {
    return false;
  }

  return project && project.in_maintenance ? true : false;
};
