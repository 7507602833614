import React, { useEffect, useMemo, useState } from "react";

// router
import { Navigate } from "react-router-dom";

// components
// shared components
import Unauthorized from "components/logout/Unauthorized";

// utils
import { hasRouteAccess } from "utils/accessControl";

import { isLoginAuth } from "utils/login";

import { isWebsiteUnderMaintenance } from "utils/maintenance";

const AuthGuard = ({ component: Component, path }) => {
  const [underMaintenance, setUnderMaintenance] = useState(false);

  useEffect(() => {
    isWebsiteUnderMaintenance().then((res) => setUnderMaintenance(res));
  }, []);

  useEffect(() => {
    if (underMaintenance) {
      import("hfn-maintenance-web/main");
    }
  }, [underMaintenance]);

  const isLoggedIn = useMemo(() => isLoginAuth(), []);
  const hasAccess = useMemo(() => hasRouteAccess(path), [path]);

  return (
    <>
      {underMaintenance ? (
        <hfn-maintenance-web />
      ) : (
        <>
          {isLoggedIn ? (
            <> {hasAccess ? <Component /> : <Unauthorized />} </>
          ) : (
            <Navigate to={{ pathname: "/login" }} />
          )}
        </>
      )}
    </>
  );
};

export default AuthGuard;
