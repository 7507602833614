import { ax } from "services/base";

// utils
import { v4 as uuidv4 } from 'uuid';

import { getLoginUser, getLoginUserRole } from "utils/login";

import { cookieStorage } from "utils/storage";

// constants
import { generalStatuses } from "utils/constants";

class LoginService {
  getCurrentUser(payload) {
    let headers = {};
    if (payload?.srcmToken) headers["Authorization"] = `Bearer ${payload.srcmToken}`;

    return payload ? ax.post("admin-user-roles", null, { headers }) : ax.post("admin-user-roles");
  }

  getRoles(payload) {
    let headers = {};
    if (payload?.srcmToken) headers["Authorization"] = `Bearer ${payload.srcmToken}`;

    return ax.get("roles", { headers, params: { pageNumber: 0, pageSize: 200, roleStatus: "ACTIVE" } }).then(res => ({
      data: {
        count: res.data.count || 0,
        results: res.data.roles || []
      }
    }));
  }

  getAbhyasiInfo(payload) {
    return ax.get("donor-profile", { params: payload });
  }

  getLoginEnvironment(payload) {
    const params = {
      currentAdminUserRoleMapId: payload?.loginUser?.userRoleId ?? getLoginUserRole()?.userRoleId ?? null,
      loggedInDeviceId: cookieStorage.get("loggedInDeviceId")
    };
    let headers = {};
    if (payload?.srcmToken) headers["Authorization"] = `Bearer ${payload.srcmToken}`;

    return ax.get("admin-user-role-map/sign-in/status", { params, headers });
  }

  setLoginEnvironment(payload) {
    let loggedInDeviceId = cookieStorage.get("loggedInDeviceId");
    if (!loggedInDeviceId) {
      loggedInDeviceId = uuidv4();
      cookieStorage.set("loggedInDeviceId", loggedInDeviceId);
    }
    const inputData = {
      currentAdminUserRoleMapId: payload?.loginUser?.userRoleId ?? getLoginUserRole()?.userRoleId ?? null,
      loggedInDeviceId: loggedInDeviceId,
      status: generalStatuses.active.value
    };
    let headers = {};
    if (payload?.srcmToken) headers["Authorization"] = `Bearer ${payload.srcmToken}`;

    return ax.post("admin-user-role-map/sign-in/status", inputData, { headers });
  }

  clearLoginEnvironment() {
    const user = getLoginUser();
    if (user?.loginUser?.userRoleId) {
      const inputData = {
        currentAdminUserRoleMapId: user.loginUser.userRoleId,
        loggedInDeviceId: cookieStorage.get("loggedInDeviceId"),
        status: generalStatuses.inactive.value
      };
      let headers = {};
      if (user?.srcmToken) headers["Authorization"] = `Bearer ${user.srcmToken}`;

      return ax.post("admin-user-role-map/sign-in/status", inputData, { headers }).catch(() => console.log("Something went wrong."));
    }
  }
}

export default LoginService;
