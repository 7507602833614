import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import AbhyasiHeader from "components/abhyasiHeader/abhyasiHeader";
import toaster from "utils/toaster";
import { ax } from "services/base";
import { ProgressSpinner } from "primereact/progressspinner";

// shared components
// import HFNLoader from "sharedComponents/lazyLoading/Loading";

//config
import config from "assets/config";

const Loader = () => (
  <div className="loader">
    <ProgressSpinner style={{ width: "40px", height: "40px" }} />
  </div>
);

const AbhyasiGeneralInfo = () => {
  const captchaRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [nameValue, setNameValue] = useState(null);
  const [emailValue, setEmailValue] = useState(null);
  const [phoneNumberValue, setPhoneNumberValue] = useState(null);
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [abhyasiIdValue, setAbhyasiIdValue] = useState(null);
  const [pincodeValue, setPincodeValue] = useState(null);
  const [addressline1Value, setAddressLine1Value] = useState(null);
  const [addressline2Value, setAddressLine2Value] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [selectedStateFilter, setSelectedStateFilter] = useState(null);
  const [selectedCityFilter, setSelectedCityFilter] = useState(null);
  const [loader, setLoader] = useState(false);
  // const [captcha, setCaptcha] = useState();
  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({
    country: "",
    state: "",
    city: "",
    name: "",
    email: "",
    selectedIdType: "",
    identificationNumber: "",
    abhyasiId: "",
    abhyasiid: "",
  });

  const idTypes = {
    pan: { label: "PAN Card", value: "Pan Card" },
    aadhar: { label: "Aadhar Card", value: "Aadhar Card" },
    dl: { label: "Driving License", value: "Driving License" },
    passport: { label: "Passport", value: "Passport" },
    ration: { label: "Ration Card", value: "Ration Card" },
    voterId: { label: "Voter ID", value: "Voter Id" },
  };

  const idTypesData = Object.entries(idTypes).map((item) => item[1]);

  const stateInputChange = (value, action) => {
    if (action.action === "input-change") {
      setSelectedStateFilter(value[0]);
    }
  };

  const cityInputChange = (value, action) => {
    if (action.action === "input-change") {
      setSelectedCityFilter(value[0]);
    }
  };

  useEffect(() => {
    const getCountry = async () => {
      const apiResponse = await axios.get(
        // The / before srcmapi is for Prod purpose
        `${config.srcmStaticDataURI}/srcmapi/countries/all.json`
      );
      let results = apiResponse.data.results;
      setCountryList(
        results.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      );
    };

    const getState = async () => {
      if (selectedCountry && selectedStateFilter) {
        const statesData = await axios.get(
          // The / before srcmapi is for Prod purpose
          `${config.srcmStaticDataURI}/srcmapi/states/${selectedStateFilter}.json`
        );
        let statesResult = statesData.data.results;
        statesResult = statesResult.filter(
          (item) => item.country === selectedCountry?.label
        );

        setStatesList(
          statesResult.map((state) => ({
            value: state.id,
            label: state.name,
          }))
        );
      } else {
        return null;
      }
    };

    const getCities = async () => {
      if (selectedCountry && selectedCityFilter) {
        const citiesData = await axios.get(
          // The / before srcmapi is for Prod purpose
          `${config.srcmStaticDataURI}/srcmapi/cities/${selectedCityFilter}.json`
        );
        let citiesResult = citiesData.data.results;

        citiesResult = citiesResult.filter(
          (item) => item.state === selectedState?.label
        );
        setCitiesList(
          citiesResult.map((state) => ({
            value: state.id,
            label: state.name,
          }))
        );
      }
    };

    getCountry();
    getState();
    getCities();
  }, [selectedCountry, selectedStateFilter, selectedCityFilter]);

  // useEffect(() => {
  //   const getRecaptcha = async () => {
  //     await captchaRef.current.executeAsync().then(async (value) => {
  //       // captchaToken.current = value;

  //       const inputData = {
  //         recaptchaResponse: value,
  //       };
  //       const apiRes = await axios.post(
  //         "https://donation-management-service-grpc.qa.heartfulnessinstitute.in/validate-recaptcha",
  //         {
  //           ...inputData,
  //         }
  //       );
  //       if (apiRes?.data?.success) {
  //         setCaptcha(apiRes?.data?.success);
  //       }
  //       if (apiRes?.data?.success === true) {
  //         toaster.success(apiRes?.data?.message);
  //       } else {
  //         toaster.error(apiRes?.data?.message);
  //       }
  //     });
  //   };
  //   getRecaptcha();
  // }, []);

  useEffect(() => {
    if (submitted) validateForm();
  }, [
    emailValue,
    nameValue,
    phoneNumberValue,
    identificationNumber,
    abhyasiIdValue,
  ]);

  const validateForm = () => {
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const pancard = /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/;
    const adhar = /^[0-9]{12}$/;
    const validabhyasiId = /^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$/;
    const dl =
      /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2})|([A-Z]{2}[0-9]{2}[A-Z]{1}))((19|20)[0-9][0-9])[0-9]{7}$/;
    const validPhoneNumber = /^[0-9]{10,14}$/;
    const newErrors = {
      country: selectedCountry ? "" : "Please select a country",
      state: selectedState ? "" : "Please select a state",
      city: selectedCity ? "" : "Please select a city",
      // pincode: pincodeValue ? "" : "Please select a pincode",
      name: nameValue ? "" : "Please enter a name",
      abhyasiid: abhyasiIdValue
        ? validabhyasiId.test(abhyasiIdValue)
          ? ""
          : "please enter valid abhyasiId"
        : "Please enter a abhyasiId",
      identificationType: selectedIdType
        ? ""
        : "Please select a identification type",
      identificationNumber: !selectedIdType
        ? "Please select an identification number"
        : selectedIdType.value === "Pan Card"
        ? pancard.test(identificationNumber)
          ? ""
          : "Please enter a valid PAN card number"
        : selectedIdType.value === "Aadhar Card"
        ? adhar.test(identificationNumber)
          ? ""
          : "Please enter a valid Aadhar card number"
        : selectedIdType.value === "Driving License"
        ? dl.test(identificationNumber)
          ? ""
          : "Please enter a valid driving license number"
        : selectedIdType.value === "Passport"
        ? identificationNumber.length < 5
          ? ""
          : "Please enter a valid Passport number"
        : selectedIdType.value === "Ration Card"
        ? identificationNumber.length < 5
          ? ""
          : "Please enter a valid Ration Card number"
        : selectedIdType.value === "Ration Card"
        ? identificationNumber.length < 5
          ? ""
          : "Please enter a valid Voter Id number"
        : "Please enter an ID number",
      email: emailValue
        ? validEmail.test(emailValue)
          ? ""
          : "please enter valid email"
        : "Please enter a email",
      phoneNumber: phoneNumberValue
        ? validPhoneNumber.test(phoneNumberValue)
          ? ""
          : "please enter valid phoneNumber"
        : "Please enter a phoneNumber",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setSubmitted(true);
    // console.log("captcha value", captcha);
    try {
      await captchaRef.current.executeAsync().then(async (value) => {
        // setCaptcha(value);
        if (validateForm()) {
          const inputData = {
            donorDetail: {
              abhyasiId: abhyasiIdValue,
              name: nameValue,
              emailId: emailValue,
              phoneNumber: phoneNumberValue,
              country: selectedCountry?.label,
              state: selectedState?.label,
              city: selectedCity?.label,
              addressLine1: addressline1Value,
              addressLine2: addressline2Value,
              firebaseId: "",
              pincode: pincodeValue,
              citizenship: "",
              status: "Active",
              donorIdentificationDetail: [
                {
                  identificationType: selectedIdType.value,
                  identificationNumber: identificationNumber,
                },
              ],
            },
            recaptchaValidationRequest: {
              recaptchaResponse: value,
            },
          };
          await ax.post(`${config.apiURI}donor-detail`, {
            ...inputData,
          });
          setLoader(false);
          toaster.success("Abhyasi general information submitted successfully");
          setSubmitted(false);
          setNameValue("");
          setEmailValue("");
          setPhoneNumberValue("");
          setSelectedCountry("");
          setSelectedState("");
          setSelectedCity("");
          setSelectedIdType("");
          setIdentificationNumber("");
          setAddressLine1Value("");
          setAddressLine2Value("");
          setAbhyasiIdValue("");
          setPincodeValue("");
        } else {
          setLoader(false);
          toaster.error("Form is not valid");
        }
      });
    } catch {
      setLoader(false);
    }
  };

  const clearForm = () => {
    setNameValue("");
    setEmailValue("");
    setPhoneNumberValue("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setSelectedIdType("");
    setIdentificationNumber("");
    setAddressLine1Value("");
    setAddressLine2Value("");
    setAbhyasiIdValue("");
    setPincodeValue("");
  };

  return (
    <div>
      <AbhyasiHeader />
      <br />
      <div className="form-page">
        <div className="form-body">
          <div className="note">
            <p>Note:</p>
            <p style={{ marginTop: "9px", marginLeft: "13px" }}>
              1. The below information is to be filled by Abhyasis living in
              india. Overseas Abhyasis need not fill these details
            </p>
            <p style={{ marginLeft: "13px" }}>
              2. The below information is collected to ensure compliance with
              recent changes made by the Government with regard to indian
              charitable organizations issuing 80G donation receipts
            </p>
          </div>
          <br />
          {loader && <Loader />}

          <Form
            onSubmit={handleSubmit}
            style={{ maxWidth: "60%", margin: "0 auto" }}
          >
            <Form.Group>
              <Form.Label className="required">Name</Form.Label>
              <br />
              <i className="fa fa-user icons"></i>
              <Form.Control
                maxLength={255}
                type="text"
                value={nameValue}
                placeholder=""
                className="input-field"
                onChange={(e) => {
                  setNameValue(e.target.value);
                  setErrors({ ...errors, name: "" });
                }}
              />
              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.name}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">Email</Form.Label>
              <br />
              <i className="fa fa-envelope icons"></i>
              <Form.Control
                type="text"
                value={emailValue}
                placeholder=""
                className="input-field"
                // onChange={handleEmailChange}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                  setErrors({ ...errors, email: "" });
                }}
              />
              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.email}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">Phone Number</Form.Label>
              <br />
              <i className="fa fa-phone icons"></i>
              <Form.Control
                maxLength={10}
                value={phoneNumberValue}
                className="input-field"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setPhoneNumberValue(e.target.value);
                  }
                  setErrors({ ...errors, phoneNumber: "" });
                }}
              />
              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.phoneNumber}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">Country</Form.Label>

              <div style={{ display: "flex" }}>
                <i className="fa fa-flag selectIcons"></i>
                <Select
                  className="select-field"
                  options={countryList}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                    setSelectedState(null);
                    setSelectedCity(null);
                    setStatesList([]);
                    setCitiesList([]);
                    setErrors({ ...errors, country: "" });
                  }}
                />
              </div>

              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.country}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">State</Form.Label>
              <div style={{ display: "flex" }}>
                <i className="fa fa-flag selectIcons"></i>

                <Select
                  className="select-field"
                  options={statesList}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                    setSelectedStateFilter(item.label.charAt(0).toLowerCase());
                    setSelectedCity(null);
                    setCitiesList([]);
                    setErrors({ ...errors, state: "" });
                  }}
                  onInputChange={stateInputChange}
                />
              </div>

              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.state}
              </div>
            </Form.Group>

            <br />
            <Form.Group>
              <Form.Label className="required">City</Form.Label>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i className="fa fa-flag selectIcons"></i>

                <Select
                  className="select-field"
                  options={citiesList}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                    setErrors({ ...errors, city: "" });
                  }}
                  onInputChange={cityInputChange}
                />
              </div>

              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.city}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">Identification Type</Form.Label>
              <br />
              <div style={{ display: "flex" }}>
                <i className="fa fa-light fa-address-card selectIcons"></i>

                <Select
                  className="select-field"
                  options={idTypesData}
                  value={selectedIdType}
                  onChange={(item) => {
                    setSelectedIdType(item);
                    setErrors({ ...errors, identificationType: "" });
                  }}
                />
              </div>

              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.identificationType}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label className="required">
                Identification Number
              </Form.Label>
              <br />
              <i className="fa fa-light fa-address-card icons"></i>
              <Form.Control
                type="text"
                value={identificationNumber}
                className="input-field"
                onChange={(e) => {
                  setIdentificationNumber(e.target.value.toUpperCase());
                  setErrors({ ...errors, identificationNumber: "" });
                }}
              />
              <div style={{ color: "red", fontSize: "13px" }}>
                {errors.identificationNumber}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Abhyasi Id</Form.Label>
              <br />
              <i className="fa fa-flag icons"></i>
              <Form.Control
                type="text"
                value={abhyasiIdValue}
                maxLength={9}
                className="input-field"
                onChange={(e) => {
                  setAbhyasiIdValue(e.target.value.toUpperCase());
                  setErrors({ ...errors, abhyasiid: "" });
                }}
              />
              <div
                style={{
                  color: "red",
                  fontSize: "13px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {errors.abhyasiid}
              </div>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Pincode</Form.Label>
              <br />
              <i className="fa fa-flag icons"></i>
              <Form.Control
                type="text"
                value={pincodeValue}
                className="input-field"
                onChange={(e) => {
                  setPincodeValue(e.target.value);
                  //   setErrors({ ...errors, pincode: "" });
                }}
              />
              {/* <div style={{ color: "red",fontSize: "13px" }}>{errors.pincode}</div> */}
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Addressline1</Form.Label>
              <br />
              {/* <i class="fa fa-flag icons"></i> */}
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={4000}
                className="textarea"
                value={addressline1Value}
                onChange={(e) => {
                  setAddressLine1Value(e.target.value);
                  setErrors({ ...errors, addressline1: "" });
                }}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Addressline2</Form.Label>
              <br />
              {/* <i class="fa fa-flag icons"></i> */}
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={4000}
                className="textarea"
                value={addressline2Value}
                onChange={(e) => {
                  setAddressLine2Value(e.target.value);
                  setErrors({ ...errors, addressline2: "" });
                }}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="dark"
                size="invisible"
                sitekey={config.siteKey}
                ref={captchaRef}
                // onClick={getCaptcha}
                // asyncScriptOnLoad={asyncScriptOnLoad}
              />
            </Form.Group>

            <p className="formnote">
              In case of any clarification, please email &nbsp;
              <a href="mailto:info.accounts@sahajmarg.org" className="email">
                info.accounts@sahajmarg.org
              </a>
            </p>
            <Form.Group className="form-btn">
              <button type="submit" className="sub-btn">
                Submit
              </button>
              &nbsp;&nbsp;
              <button type="submit" className="sub-btn" onClick={clearForm}>
                Cancel
              </button>
            </Form.Group>

            <br />
          </Form>
        </div>
      </div>
      <div className="form-footer">
        © Copyright Sahaj Marg Spirituality Foundation, All Rights Reserved.
      </div>
    </div>
  );
};

export default AbhyasiGeneralInfo;
