import React, { memo, useState, useMemo, useCallback, useRef } from "react";

// router
import { useNavigate } from "react-router-dom";

// components
import { Button } from "primereact/button";

// shared components
import HFNLoader from "sharedComponents/lazyLoading/Loading";

import HFNDynamicForm from "sharedComponents/hfnForm";

// utils
import { isEmpty } from "lodash";

import validations from "utils/validations";

import modalPopup from "utils/modalPopup";

import { getLoginUser, setLoginUser } from "utils/login";

import { getHomePath } from "utils/common";

import { lStorage } from "utils/storage";

// services
import Service from "services/login.service";

// constants
import {
  ROLE_SA,
  ROLE_CT,
  ROLE_SG,
  ROLE_CC,
  ROLE_CFC,
  ROLE_DC,
  ROLE_SDC,
  printerOptionses,
} from "utils/constants";

const LoginRole = ({
  closePanel,
  roleList,
  loginUserRoles: { isValidUser, loginRoles, hasMoreRoles },
  user,
  isLogin,
}) => {
  const [loader, setLoader] = useState(false);

  const printerType = useRef();

  const formRef = useRef(null);

  let navigate = useNavigate();

  const service = useMemo(() => new Service(), []);
  let printerTypeValue = lStorage.get("printerTypes");

  const [
    isValid,
    roles,
    countries,
    organizations,
    centers,
    initValue,
    disabilityChecks,
    donationRoles,
  ] = useMemo(() => {
    let userIsValid = true,
      userRoles,
      userCountry,
      userOrganization,
      userCenter,
      initialValues = {},
      disables = {},
      allRoles = roleList;
    let currentUserValues = {},
      isValidCurrentUser = !isLogin;
    let currentCountries = [],
      currentOrganizations = [],
      currentCenters = [],
      currentDisables = {};
    try {
      if (isValidUser) {
        const currentUser = getLoginUser();
        const roleName = loginRoles.roles[0].roleName;

        if (!isLogin) allRoles = currentUser.roles;

        if (
          isValidCurrentUser &&
          loginRoles.roles.find((item) => item.id === currentUser.loginRole.id)
        )
          currentUserValues.role = currentUser.loginRole;
        else isValidCurrentUser = false;

        initialValues.role = loginRoles.roles[0];
        userRoles = loginRoles.roles;
        if (loginRoles.roles.length === 1) disables.role = true;

        if (roleName === ROLE_CT) {
          const countryOrgs = loginRoles.ct.organizations.filter(
            (item) => item.country.id === loginRoles.ct.countries[0].id
          );
          initialValues.country = loginRoles.ct.countries[0];
          initialValues.organization = countryOrgs[0];
          if (loginRoles.ct.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;

          userCountry = loginRoles.ct.countries;
          userOrganization = countryOrgs;
        }
        if (roleName === ROLE_SG) {
          const countryOrgs = loginRoles.sg.organizations.filter(
            (item) => item.country.id === loginRoles.sg.countries[0].id
          );
          const orgCenters = loginRoles.sg.centers.filter(
            (item) => item.organization.id === countryOrgs[0].id
          );
          initialValues.country = loginRoles.sg.countries[0];
          initialValues.organization = countryOrgs[0];
          initialValues.center = orgCenters[0];
          if (loginRoles.sg.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;
          if (orgCenters.length === 1) disables.center = true;

          userCountry = loginRoles.sg.countries;
          userOrganization = countryOrgs;
          userCenter = orgCenters;
        }
        if (roleName === ROLE_CC) {
          const countryOrgs = loginRoles.cc.organizations.filter(
            (item) => item.country.id === loginRoles.cc.countries[0].id
          );
          const orgCenters = loginRoles.cc.centers.filter(
            (item) => item.organization.id === countryOrgs[0].id
          );
          initialValues.country = loginRoles.cc.countries[0];
          initialValues.organization = countryOrgs[0];
          initialValues.center = orgCenters[0];
          if (loginRoles.cc.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;
          if (orgCenters.length === 1) disables.center = true;

          userCountry = loginRoles.cc.countries;
          userOrganization = countryOrgs;
          userCenter = orgCenters;
        }
        if (roleName === ROLE_CFC) {
          const countryOrgs = loginRoles.cfc.organizations.filter(
            (item) => item.country.id === loginRoles.cfc.countries[0].id
          );
          const orgCenters = loginRoles.cfc.centers.filter(
            (item) => item.organization.id === countryOrgs[0].id
          );
          initialValues.country = loginRoles.cfc.countries[0];
          initialValues.organization = countryOrgs[0];
          initialValues.center = orgCenters[0];
          if (loginRoles.cfc.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;
          if (orgCenters.length === 1) disables.center = true;

          userCountry = loginRoles.cfc.countries;
          userOrganization = countryOrgs;
          userCenter = orgCenters;
        }
        if (roleName === ROLE_DC) {
          const countryOrgs = loginRoles.dc.organizations.filter(
            (item) => item.country.id === loginRoles.dc.countries[0].id
          );
          const orgCenters = loginRoles.dc.centers.filter(
            (item) => item.organization.id === countryOrgs[0].id
          );
          initialValues.printer = printerOptionses[0];
          initialValues.country = loginRoles.dc.countries[0];
          initialValues.organization = countryOrgs[0];
          initialValues.center = orgCenters[0];
          if (loginRoles.dc.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;
          if (orgCenters.length === 1) disables.center = true;

          userCountry = loginRoles.cc.countries;
          userOrganization = countryOrgs;
          userCenter = orgCenters;
        }
        if (roleName === ROLE_SDC) {
          const countryOrgs = loginRoles.sdc.organizations.filter(
            (item) => item.country.id === loginRoles.sdc.countries[0].id
          );
          const orgCenters = loginRoles.sdc.centers.filter(
            (item) => item.organization.id === countryOrgs[0].id
          );
          initialValues.country = loginRoles.sdc.countries[0];
          initialValues.organization = countryOrgs[0];
          initialValues.center = orgCenters[0];
          if (loginRoles.sdc.countries.length === 1) disables.country = true;
          if (countryOrgs.length === 1) disables.organization = true;
          if (orgCenters.length === 1) disables.center = true;

          userCountry = loginRoles.cc.countries;
          userOrganization = countryOrgs;
          userCenter = orgCenters;
        }

        if (isValidCurrentUser && currentUser.loginRole.roleName === ROLE_CT) {
          if (
            loginRoles.ct.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.ct.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.ct.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            currentCountries = loginRoles.ct.countries;
            currentOrganizations = countryOrgs;
            if (loginRoles.ct.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
          }
        } else if (
          isValidCurrentUser &&
          currentUser.loginRole.roleName === ROLE_SG
        ) {
          if (
            loginRoles.sg.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.sg.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.sg.centers.find(
              (item) => item.id === currentUser.loginUser.center.id
            )
          )
            currentUserValues.center = currentUser.loginUser.center;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.sg.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            const orgCenters = loginRoles.sg.centers.filter(
              (item) =>
                item.organization.id === currentUser.loginUser.organization.id
            );
            currentCountries = loginRoles.sg.countries;
            currentOrganizations = countryOrgs;
            currentCenters = orgCenters;
            if (loginRoles.sg.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
            if (orgCenters.length === 1) currentDisables.center = true;
          }
        } else if (
          isValidCurrentUser &&
          currentUser.loginRole.roleName === ROLE_CC
        ) {
          if (
            loginRoles.cc.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.cc.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.cc.centers.find(
              (item) => item.id === currentUser.loginUser.center.id
            )
          )
            currentUserValues.center = currentUser.loginUser.center;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.cc.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            const orgCenters = loginRoles.cc.centers.filter(
              (item) =>
                item.organization.id === currentUser.loginUser.organization.id
            );
            currentCountries = loginRoles.cc.countries;
            currentOrganizations = countryOrgs;
            currentCenters = orgCenters;
            if (loginRoles.cc.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
            if (orgCenters.length === 1) currentDisables.center = true;
          }
        }
        if (isValidCurrentUser && currentUser.loginRole.roleName === ROLE_CFC) {
          if (
            loginRoles.cfc.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.cfc.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.cfc.centers.find(
              (item) => item.id === currentUser.loginUser.center.id
            )
          )
            currentUserValues.center = currentUser.loginUser.center;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.cfc.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            const orgCenters = loginRoles.cfc.centers.filter(
              (item) =>
                item.organization.id === currentUser.loginUser.organization.id
            );
            currentCountries = loginRoles.cfc.countries;
            currentOrganizations = countryOrgs;
            currentCenters = orgCenters;
            if (loginRoles.cfc.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
            if (orgCenters.length === 1) currentDisables.center = true;
          }
        }
        if (isValidCurrentUser && currentUser.loginRole.roleName === ROLE_DC) {
          if (
            loginRoles.dc.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.dc.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.dc.centers.find(
              (item) => item.id === currentUser.loginUser.center.id
            )
          )
            currentUserValues.center = currentUser.loginUser.center;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.dc.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            const orgCenters = loginRoles.dc.centers.filter(
              (item) =>
                item.organization.id === currentUser.loginUser.organization.id
            );
            currentCountries = loginRoles.dc.countries;
            currentOrganizations = countryOrgs;
            currentCenters = orgCenters;
            if (loginRoles.dc.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
            if (orgCenters.length === 1) currentDisables.center = true;
          }
        }
        if (isValidCurrentUser && currentUser.loginRole.roleName === ROLE_SDC) {
          if (
            loginRoles.sdc.countries.find(
              (item) => item.id === currentUser.loginUser.country.id
            )
          )
            currentUserValues.country = currentUser.loginUser.country;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.sdc.organizations.find(
              (item) => item.id === currentUser.loginUser.organization.id
            )
          )
            currentUserValues.organization = currentUser.loginUser.organization;
          else isValidCurrentUser = false;
          if (
            isValidCurrentUser &&
            loginRoles.sdc.centers.find(
              (item) => item.id === currentUser.loginUser.center.id
            )
          )
            currentUserValues.center = currentUser.loginUser.center;
          else isValidCurrentUser = false;

          if (isValidCurrentUser) {
            const countryOrgs = loginRoles.sdc.organizations.filter(
              (item) => item.country.id === currentUser.loginUser.country.id
            );
            const orgCenters = loginRoles.sdc.centers.filter(
              (item) =>
                item.organization.id === currentUser.loginUser.organization.id
            );
            currentCountries = loginRoles.sdc.countries;
            currentOrganizations = countryOrgs;
            currentCenters = orgCenters;
            if (loginRoles.sdc.countries.length === 1)
              currentDisables.country = true;
            if (countryOrgs.length === 1) currentDisables.organization = true;
            if (orgCenters.length === 1) currentDisables.center = true;
          }
        }
      }
    } catch {
      userIsValid = false;
    }

    return isValidCurrentUser
      ? [
          userIsValid,
          userRoles,
          currentCountries,
          currentOrganizations,
          currentCenters,
          currentUserValues,
          currentDisables,
          allRoles,
        ]
      : [
          userIsValid,
          userRoles,
          userCountry,
          userOrganization,
          userCenter,
          initialValues,
          disables,
          allRoles,
        ];
  }, []);

  const formFields = useMemo(
    () => ({
      role: {
        properties: {
          type: "Select",
          label: "Role",
          primeFieldProps: {
            options: roles,
            isDisabled: hasMoreRoles === false ? true : false,
            onChange: (value, setValue) => {
              if (value && value.id) {
                const roleName = value.roleName;
                let options = {},
                  disables = {};
                if (roleName === ROLE_CT) {
                  const countryOrgs = loginRoles.ct.organizations.filter(
                    (item) => item.country.id === loginRoles.ct.countries[0].id
                  );
                  setValue("country", loginRoles.ct.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  if (loginRoles.ct.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;

                  options.country = loginRoles.ct.countries;
                  options.organization = countryOrgs;
                } else if (roleName === ROLE_SG) {
                  const countryOrgs = loginRoles.sg.organizations.filter(
                    (item) => item.country.id === loginRoles.sg.countries[0].id
                  );
                  const orgCenters = loginRoles.sg.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("country", loginRoles.sg.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);

                  if (loginRoles.sg.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.country = loginRoles.sg.countries;
                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_CC) {
                  const countryOrgs = loginRoles.cc.organizations.filter(
                    (item) => item.country.id === loginRoles.cc.countries[0].id
                  );
                  const orgCenters = loginRoles.cc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("country", loginRoles.cc.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (loginRoles.cc.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.country = loginRoles.cc.countries;
                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_CFC) {
                  const countryOrgs = loginRoles.cfc.organizations.filter(
                    (item) => item.country.id === loginRoles.cfc.countries[0].id
                  );
                  const orgCenters = loginRoles.cfc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("country", loginRoles.cfc.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (loginRoles.cc.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.country = loginRoles.cc.countries;
                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_DC) {
                  const countryOrgs = loginRoles.dc.organizations.filter(
                    (item) => item.country.id === loginRoles.dc.countries[0].id
                  );
                  const orgCenters = loginRoles.dc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("printer", "");
                  lStorage.remove("printerTypes");
                  setValue("country", loginRoles.dc.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (loginRoles.dc.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.country = loginRoles.dc.countries;
                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_SDC) {
                  const countryOrgs = loginRoles.sdc.organizations.filter(
                    (item) => item.country.id === loginRoles.sdc.countries[0].id
                  );
                  const orgCenters = loginRoles.sdc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("printer", "");
                  lStorage.remove("printerTypes");
                  setValue("country", loginRoles.sdc.countries[0]);
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (loginRoles.sdc.countries.length === 1)
                    disables.country = true;
                  else disables.country = false;
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.country = loginRoles.sdc.countries;
                  options.organization = countryOrgs;
                  options.center = orgCenters;
                }

                if (
                  (!isEmpty(options) || !isEmpty(disables)) &&
                  formRef.current
                )
                  formRef.current.setOptions(options, disables);
              }
            },
          },
          validations: { required: validations.required },
        },
      },
      printer: {
        properties: {
          type: "Select",
          label: "Printer",
          dropdownOptions: "printerOptions",
          primeFieldProps: {
            onChange: (value) => {
              // lStorage.set("printerTypes", value);
              printerType.current = value;
            },
          },
          validations: { required: validations.required },
        },
      },
      country: {
        properties: {
          type: "Select",
          label: "Country",
          primeFieldProps: {
            options: countries,
            onChange: (value, setValue, isSubmitted, getValues) => {
              if (value && value.id) {
                const roleName = getValues("role").roleName;
                const countryID = value.id;
                let options = {},
                  disables = {};
                if (roleName === ROLE_CT) {
                  const countryOrgs = loginRoles.ct.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  setValue("organization", countryOrgs[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;

                  options.organization = countryOrgs;
                } else if (roleName === ROLE_SG) {
                  const countryOrgs = loginRoles.sg.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  const orgCenters = loginRoles.sg.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_CC) {
                  const countryOrgs = loginRoles.cc.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  const orgCenters = loginRoles.cc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_CFC) {
                  const countryOrgs = loginRoles.cfc.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  const orgCenters = loginRoles.cfc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_DC) {
                  const countryOrgs = loginRoles.dc.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  const orgCenters = loginRoles.dc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.organization = countryOrgs;
                  options.center = orgCenters;
                } else if (roleName === ROLE_SDC) {
                  const countryOrgs = loginRoles.sdc.organizations.filter(
                    (item) => item.country.id === countryID
                  );
                  const orgCenters = loginRoles.sdc.centers.filter(
                    (item) => item.organization.id === countryOrgs[0].id
                  );
                  setValue("organization", countryOrgs[0]);
                  setValue("center", orgCenters[0]);
                  if (countryOrgs.length === 1) disables.organization = true;
                  else disables.organization = false;
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.organization = countryOrgs;
                  options.center = orgCenters;
                }

                if (
                  (!isEmpty(options) || !isEmpty(disables)) &&
                  formRef.current
                )
                  formRef.current.setOptions(options, disables);
              }
            },
          },
          validations: { required: validations.required },
        },
      },
      organization: {
        properties: {
          type: "Select",
          label: "Organization",
          primeFieldProps: {
            options: organizations,
            onChange: (value, setValue, isSubmitted, getValues) => {
              if (value && value.id) {
                const roleName = getValues("role").roleName;
                const orgID = value.id;
                let options = {},
                  disables = {};
                if (roleName === ROLE_CC) {
                  const orgCenters = loginRoles.cc.centers.filter(
                    (item) => item.organization.id === orgID
                  );
                  setValue("center", orgCenters[0]);
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.center = orgCenters;
                } else if (roleName === ROLE_CFC) {
                  const orgCenters = loginRoles.cfc.centers.filter(
                    (item) => item.organization.id === orgID
                  );
                  setValue("center", orgCenters[0]);
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.center = orgCenters;
                } else if (roleName === ROLE_DC) {
                  const orgCenters = loginRoles.dc.centers.filter(
                    (item) => item.organization.id === orgID
                  );
                  setValue("center", orgCenters[0]);
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.center = orgCenters;
                } else if (roleName === ROLE_SG) {
                  const orgCenters = loginRoles.sg.centers.filter(
                    (item) => item.organization.id === orgID
                  );
                  setValue("center", orgCenters[0]);
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.center = orgCenters;
                } else if (roleName === ROLE_SDC) {
                  const orgCenters = loginRoles.sdc.centers.filter(
                    (item) => item.organization.id === orgID
                  );
                  setValue("center", orgCenters[0]);
                  if (orgCenters.length === 1) disables.center = true;
                  else disables.center = false;

                  options.center = orgCenters;
                }

                if (
                  (!isEmpty(options) || !isEmpty(disables)) &&
                  formRef.current
                )
                  formRef.current.setOptions(options, disables);
              }
            },
          },
          validations: { required: validations.required },
        },
      },
      center: {
        properties: {
          type: "Select",
          label: "Center",
          primeFieldProps: { options: centers, menuPlacement: "top" },
          validations: { required: validations.required },
        },
      },
    }),
    []
  );

  const visibilityCheck = useCallback((values, name, type) => {
    let visibles = {};
    if (name) {
      if (type === "change") {
        const value = values[name];
        if (name === "role") {
          const roleName = value ? value.roleName : null;
          if (roleName === ROLE_CT)
            return {
              country: true,
              organization: true,
              center: false,
              printer: false,
            };
          if (roleName === ROLE_DC || roleName === ROLE_SDC)
            return {
              country: true,
              organization: true,
              center: true,
              printer: true,
            };
          if ([ROLE_CC, ROLE_CFC, ROLE_SG].includes(roleName))
            return {
              country: true,
              organization: true,
              center: true,
              printer: false,
            };
          return {
            country: false,
            organization: false,
            center: false,
            printer: false,
          };
        }
      }
    } else {
      const roleName = values.role.roleName;
      if (roleName === ROLE_CT) return { center: false, printer: false };
      if ([ROLE_DC, ROLE_SDC].includes(roleName))
        return {
          country: true,
          organization: true,
          center: true,
          printer: true,
        };
      //  if ([ROLE_CC, ROLE_CFC, ROLE_DC, ROLE_SG, ROLE_SDC].includes(roleName))
      //    return {};
      if ([ROLE_CC, ROLE_CFC, ROLE_SG].includes(roleName))
        return { printer: false };
      return {
        country: false,
        organization: false,
        center: false,
        printer: false,
      };
    }
    return visibles;
  }, []);

  const disabilityCheck = useCallback((values, name) => {
    if (!name) return disabilityChecks;
    return {};
  }, []);

  const formButtonGroup = useCallback(
    () => (
      <div className="form-button-group">
        <Button
          type="button"
          label={isLogin ? "Logout" : "Close"}
          className="p-button p-button-secondary p-mr-2"
          onClick={closePanel}
        />
        <Button
          type="submit"
          label="Proceed"
          className="p-button p-button-primary"
        />
      </div>
    ),
    []
  );

  const formOnsubmit = useCallback(async (data) => {
    if ([ROLE_DC, ROLE_SDC].includes(data.role.roleName)) {
      lStorage.set("printerTypes", printerType.current || "");
    }
    setLoader(true);
    try {
      let authUser = {
        ...user,
        roles: donationRoles,
        loginRole: data.role,
        loginRoles: loginRoles,
        hasMoreRoles: hasMoreRoles,
      };

      if (ROLE_SA === data.role.roleName) authUser.loginUser = loginRoles.sa;
      if ([ROLE_CT].includes(data.role.roleName))
        authUser.loginUser = {
          ...data.organization.adminUser,
          country: data.country,
          organization: data.organization,
        };
      if (
        [ROLE_CC, ROLE_CFC, ROLE_DC, ROLE_SG, ROLE_SDC].includes(
          data.role.roleName
        )
      )
        authUser.loginUser = {
          ...data.center.adminUser,
          country: data.country,
          organization: data.organization,
          center: data.center,
        };

      lStorage.remove("session");
      await service.clearLoginEnvironment();
      await service.getLoginEnvironment(authUser);
      await service.setLoginEnvironment(authUser);

      setLoginUser(authUser);
      setLoader(false);
      if (isLogin) modalPopup.toggle(false);
      else setTimeout(() => window.location.reload());
      navigate(getHomePath());
    } catch {
      setLoader(false);
      console.log("Something went wrong.");
    }
  }, []);

  const initValuess = { ...initValue, printer: printerTypeValue };
  return (
    <>
      {loader === true ? <HFNLoader /> : null}
      {isValid && isValidUser ? (
        <HFNDynamicForm
          ref={formRef}
          initialValues={initValuess}
          fields={formFields}
          visibilityCheck={visibilityCheck}
          disabilityCheck={disabilityCheck}
          submitButtonGroup={formButtonGroup}
          onFormSubmit={formOnsubmit}
        />
      ) : (
        <div>
          <div className="unauthorized-page-access">Unauthorized Access!</div>
          <p className="unauthorized-page-message p-text-center p-m-2">
            You don&apos;t have access to the screen. Please contact
            administrator for more details.
          </p>
          <div className="p-field p-text-center p-mx-6 p-mb-6 p-mt-5">
            <Button
              label="Close"
              className="p-mt-4 p-mb-2 p-mr-3 p-button-secondary"
              onClick={closePanel}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(LoginRole);
