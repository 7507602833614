import { APP_OPENSIDEBAR, APP_UPDATEBREADCRUMB, APP_FIREBASEUSER } from "store/actionTypes/app";

import { RESET } from "store/actionTypes/login";

// constants
const initialState = {
  isSidebarOpen: (window.screen.width > 767) ? false : true,
  breadcrumb: [],
  firebaseUser: null
};

// reducer
const appDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...state, ...initialState };
    case APP_OPENSIDEBAR:
      return { ...state, isSidebarOpen: action.payload };
    case APP_UPDATEBREADCRUMB:
      return { ...state, breadcrumb: action.payload };
    case APP_FIREBASEUSER:
      return { ...state, firebaseUser: action.payload }; 
    default:
      return state;
  }
};

export default appDetails;
