import React from "react";

//utils
import { getAmount, getDateString, lookup } from "utils/common";

// constants
import { citizenshipFilterByName, sessionStatuses } from "utils/constants";

export const statusTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let status = "",
    statusClass = "";

  if (rowData[field] === "ACTIVE") {
    status = "Active";
    statusClass = "p-badge status-active";
  } else if (rowData[field] === "INACTIVE") {
    status = "Inactive";
    statusClass = "p-badge status-in-active";
  } else if (rowData[field] === "CLOSED") {
    status = "Closed";
    statusClass = "p-badge status-closed";
  }

  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </React.Fragment>
  );
};

export const sessionStatusTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let status = "",
    statusClass = "";

  if (rowData[field] === "OPEN") {
    status = "OPEN";
    statusClass = "p-badge status-active";
  } else if (!rowData[field] || rowData[field] === "CLOSED") {
    status = "CLOSED";
    statusClass = "p-badge status-in-active";
  }

  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={status}
      >
        <div className={statusClass}> {status} </div>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={status}>
        <div className={statusClass}> {status} </div>
      </div>
    </React.Fragment>
  );
};

export const displayNameTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  },
  names
) => {
  const displayName = names[rowData[field]]?.label || "";
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={displayName}
      >
        {displayName}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={displayName}
      >
        {displayName}
      </div>
    </React.Fragment>
  );
};

export const displayEmailMobileNumber = (
  rowData,
  { props: { header, headerStyle } }
) => {
  const email = rowData.data.email || "";
  const mobileNumber = rowData.data.mobileNumber || "";
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={email}
      >
        <span>{email}</span>
        <br />
        <span>{mobileNumber}</span>
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={email}>
        <span>{email}</span>
        <br />
        <span>{mobileNumber}</span>
      </div>
    </React.Fragment>
  );
};

export const displayEditedUpdatedBy = (
  rowData,
  { props: { header, headerStyle } }
) => {
  const createdBy = rowData.createdBy || "";
  const editedBy = rowData.editedBy || "";
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={createdBy}
      >
        <span>
          {createdBy}
          {editedBy}
        </span>
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={createdBy}
      >
        <span>
          {createdBy}
          {editedBy}
        </span>
      </div>
    </React.Fragment>
  );
};

export const displayCreatedUpdatedOn = (
  rowData,
  { props: { header, headerStyle } },
  dateFormat
) => {
  const value1 = rowData.createdOn || "";
  const value2 = rowData.updatedOn || "";
  const date1 = getDateString(value1, dateFormat);
  const date2 = getDateString(value2, dateFormat);
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      {value1 || value2 ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
          >
            <span>{rowData.auditType === "CREATED" ? date1 : date2}</span>
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td">
            <span>{rowData.auditType === "CREATED" ? date1 : date2}</span>
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td"> {"-"} </div>
      )}
    </React.Fragment>
  );
};

export const ObjArrayBadge = (rowData, { field }) => {
  if (Array.isArray(rowData[field])) {
    return (
      <ul className="p-pl-2">
        {rowData[field].map(({ name, id }) => {
          return (
            <li key={id} title={name || ""}>
              {" "}
              {name || ""}{" "}
            </li>
          );
        })}
      </ul>
    );
  } else return "";
};

export const amountBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const amount = getAmount(rowData, field);
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={amount}
      >
        {amount || "-"}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title={amount}>
        {amount || ""}
      </div>
    </React.Fragment>
  );
};

export const sessionIdsBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const sessionId = lookup(rowData, field);
  const sessionIds = sessionId.toString();
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={sessionIds}
      >
        {sessionIds || "-"}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={sessionIds}
      >
        {sessionIds || ""}
      </div>
    </React.Fragment>
  );
};

export const createdDateBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  },
  dateFormat
) => {
  const value = lookup(rowData, field);
  const date = getDateString(value, dateFormat);
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      {value ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
            title={date}
          >
            {date}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td" title={date}>
            {date}
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td"> {"-"} </div>
      )}
    </React.Fragment>
  );
};

export const boolBadge = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  let cellValue = null;
  if (typeof rowData[field] === "boolean")
    cellValue = rowData[field] ? "Yes" : "No";
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={cellValue ?? ""}
      >
        {cellValue ?? ""}
      </div>
    </React.Fragment>
  );
};

export const cityACTemplate = ({ name, state, country }, { context }) => {
  if (context === "menu")
    return (
      <div className="cac-wrapper">
        <div className="cac-name">{name}</div>
        <div className="cac-sc-wrapper">
          <span className="cac-sc">
            {state}
            {state ? <span>&nbsp;,&nbsp;</span> : <></>}
            {country}
          </span>
        </div>
      </div>
    );
  else
    return name
      ? name + (state ? ", " + state : "") + (country ? ", " + country : "")
      : null;
};

export const accountTemplate = (
  {
    accountNumber = "",
    bankName = "",
    citizenship = "",
    status = "",
    page = "",
  },
  { context }
) => {
  const citizenshipLabel = citizenshipFilterByName[citizenship]?.label || "";
  if (context === "menu")
    return (
      <div className="cac-wrapper">
        <div className="cac-name">{accountNumber}</div>
        <div className="cac-sc-wrapper">
          <span className="cac-sc">
            {page === "upload"
              ? bankName +
                (citizenshipLabel
                  ? bankName
                    ? `, ${citizenshipLabel} (${status})`
                    : citizenshipLabel
                  : "")
              : bankName +
                (citizenshipLabel
                  ? bankName
                    ? `, ${citizenshipLabel}`
                    : citizenshipLabel
                  : "")}
          </span>
        </div>
      </div>
    );
  else {
    let fieldDisplayName = accountNumber;
    if (citizenshipLabel)
      fieldDisplayName += fieldDisplayName
        ? ", " + citizenshipLabel
        : citizenshipLabel;
    return fieldDisplayName;
  }
};

export const submittedDateTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  const value = lookup(rowData, field);
  let date = getDateString(value, "MMM DD, YYYY HH:mm A") || "";
  if (
    [sessionStatuses.live.value, sessionStatuses.pendingDc.value].includes(
      rowData.sessionStatus
    )
  )
    date = "";

  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      {value ? (
        <>
          <div
            className="hfn-datatable-td hfn-header-style-td"
            style={headerStyle}
            title={date}
          >
            {date}
          </div>
          <div className="hfn-datatable-td hfn-no-header-style-td" title={date}>
            {date}
          </div>
        </>
      ) : (
        <div className="hfn-datatable-td"> {""} </div>
      )}
    </React.Fragment>
  );
};

export const centerTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  if (Array.isArray(rowData[field])) {
    return (
      <>
        <div className="p-column-title"> {header} </div>
        <div
          className="hfn-datatable-td hfn-header-style-td"
          style={headerStyle}
          title=""
        >
          {rowData[field].map((item, key) =>
            rowData[field].length === key + 1
              ? item?.center.centerName
              : `${item?.center.centerName}, `
          )}
        </div>
        <div className="hfn-datatable-td hfn-no-header-style-td" title="">
          {rowData[field].map((item, key) =>
            rowData[field].length === key + 1
              ? item?.center.centerName
              : `${item?.center.centerName}, `
          )}
        </div>
      </>
    );
  }
  return "";
};

export const transactionCountTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title=""
      >
        {rowData[field] || 0}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title="">
        {rowData[field] || 0}
      </div>
    </>
  );
};

export const displayByValue = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <>
      <div className="p-column-title"> {header} </div>
      <div
        className="hfn-datatable-td hfn-header-style-td"
        style={headerStyle}
        title=""
      >
        {rowData[field] || "-"}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td" title="">
        {rowData[field] || "-"}
      </div>
    </>
  );
};

export const displayLogSessionTemplate = (
  rowData,
  {
    field,
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div className="hfn-datatable-td hfn-header-style-td" style={headerStyle}>
        {rowData[field] || "-"}
      </div>
      <div
        className="hfn-datatable-td hfn-no-header-style-td"
        title={rowData[field]}
      >
        {rowData[field] || "-"}
      </div>
    </React.Fragment>
  );
};

export const displayLogSessionForOrgCode = (
  rowData,
  {
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div className="hfn-datatable-td hfn-header-style-td" style={headerStyle}>
        {rowData.adminUserAuditLogDetail.orgCode || "-"}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td">
        {rowData.adminUserAuditLogDetail.orgCode || "-"}
      </div>
    </React.Fragment>
  );
};

export const displayLogSessionForRoleName = (
  rowData,
  {
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div className="hfn-datatable-td hfn-header-style-td" style={headerStyle}>
        {rowData.adminUserAuditLogDetail.roleName || "-"}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td">
        {rowData.adminUserAuditLogDetail.roleName || "-"}
      </div>
    </React.Fragment>
  );
};

export const displayLogSessionForCenterCode = (
  rowData,
  {
    column: {
      props: { header, headerStyle },
    },
  }
) => {
  return (
    <React.Fragment>
      <div className="p-column-title"> {header} </div>
      <div className="hfn-datatable-td hfn-header-style-td" style={headerStyle}>
        {rowData.adminUserAuditLogDetail.centerCode || "-"}
      </div>
      <div className="hfn-datatable-td hfn-no-header-style-td">
        {rowData.adminUserAuditLogDetail.centerCode || "-"}
      </div>
    </React.Fragment>
  );
};
