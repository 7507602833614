// state
import appStore from "store";

import { APP_OPENSIDEBAR } from "store/actionTypes/app";

//utils
import axios from "axios";

import moment from "moment";

import print from "print-js";

import { isEmpty, isString, isObject, isArray } from "lodash";

import { saveAs } from "file-saver";

import { getLoginRole } from "utils/login";

import validations from "utils/validations";

import toaster from "utils/toaster";

// constants
import {
  ROLE_SA,
  ROLE_CT,
  ROLE_SG,
  ROLE_CC,
  ROLE_CFC,
  ROLE_DC,
  ROLE_SDC,
} from "utils/constants";

const base64Marker = ";base64,";

export const getDateString = (
  inputDate = null,
  dateFormat = "MMM DD, YYYY"
) => {
  let date = inputDate;
  if (!(inputDate instanceof Date)) date = new Date(inputDate);
  if (!isNaN(date)) {
    return moment(date).format(dateFormat);
  }
  return null;
};

export const getResponseMessage = (apiResponseData) => {
  let errMsg = "",
    message,
    errObj;

  if (!isEmpty(apiResponseData) && !isEmpty(apiResponseData.message)) {
    message = apiResponseData.message;
    if (isString(message)) {
      errMsg = message;
    }

    if (isObject(message)) {
      errObj = Object.keys(message[0]);

      if (isArray(errObj)) {
        errMsg = message[0][errObj[0]][0];
      }
    }

    if (isArray(message)) {
      if (isObject(message[0])) {
        errObj = Object.keys(message[0]);

        if (isArray(errObj)) {
          errMsg = message[0][errObj[0]][0];
        }
      } else if (isString(message[0])) {
        errMsg = message[0];
      }
    }
  }
  return errMsg;
};

export const getHomePath = () => {
  const roleName = getLoginRole().roleName;
  if (roleName === ROLE_SA) return "/country-treasurer";
  else if (roleName === ROLE_CT) return "/donation-update";
  else if (roleName === ROLE_SG) return "/session-dashboard";
  else if (roleName === ROLE_CC) return "/view-donation";
  else if (roleName === ROLE_CFC) return "/confirm-session";
  else if (roleName === ROLE_DC) return "/donation";
  else if (roleName === ROLE_SDC) return "/donation";
  else return "/login";
};

export const randomText = (val) => {
  return Array.from(Array(val), () =>
    Math.floor(Math.random() * 36).toString(36)
  ).join("");
};

export const getTotalHours = (val) => {
  if (val && Number.isInteger(val)) {
    const minutes = val % 60;
    const hours = parseInt(val / 60);

    if (Number.isInteger(minutes) && Number.isInteger(hours))
      return (
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes)
      );
  }
};

export const downloadFile = (
  content,
  name = "download",
  type = "url",
  mimeType = ""
) => {
  if (content) {
    if (["url", "blob"].includes(type)) saveAs(content, name);
    else if (type === "StringByteArray") {
      let base64String = content;
      if (base64String.includes(base64Marker)) {
        const base64Index =
          base64String.indexOf(base64Marker) + base64Marker.length;
        base64String = base64String.substring(base64Index);
      }
      const binaryString = window.atob(base64String);
      const byteArray = Uint8Array.from(binaryString, (value) =>
        value.charCodeAt(0)
      );
      const file = new Blob([byteArray], { type: mimeType });
      saveAs(file, name);
    }
  }
};

export const toggleSideBar = () => {
  const isSidebarOpen = appStore.getState().appDetails.isSidebarOpen;
  appStore.dispatch({ type: APP_OPENSIDEBAR, payload: !isSidebarOpen });
};

export const lookup = (obj, key) => {
  return key.split(".").reduce((o, k) => o && o[k], obj);
};

export const getAmount = (obj, key) => {
  let amount = lookup(obj, key);
  if (typeof amount === "string") {
    if (amount.includes(".")) return amount;
    else if (validations.numberText.value.test(amount)) return amount + ".00";
  }
  return "";
};

export const printFile = (stringByteArray, errMsg, onClose) => {
  let base64String = stringByteArray;
  if (base64String.includes(base64Marker)) {
    const base64Index =
      base64String.indexOf(base64Marker) + base64Marker.length;
    base64String = base64String.substring(base64Index);
  }
  const binaryString = window.atob(base64String);
  const byteArray = Uint8Array.from(binaryString, (value) =>
    value.charCodeAt(0)
  );
  const file = new Blob([byteArray], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  let printData = {
    printable: fileURL,
    onError: (error) =>
      toaster.error(
        (errMsg || "Unable to print.") + " " + (error?.message || "")
      ),
  };
  if (onClose) printData.onPrintDialogClose = onClose;

  print(printData);

  return fileURL;
};

export const getAndPrintFile = async (url, errMsg, onClose) => {
  try {
    let apiResponse = await axios.get(url, { responseType: "blob" });
    if (apiResponse?.data) {
      const fileURL = URL.createObjectURL(apiResponse.data);
      let printData = {
        printable: fileURL,
        onError: (error) =>
          toaster.error(
            (errMsg || "Unable to print.") + " " + (error?.message || "")
          ),
      };
      if (onClose) printData.onPrintDialogClose = onClose;

      print(printData);
    }
  } catch {
    console.log("Something went wrong.");
  }
};

export const getAndDownloadFile = async (url, fileName) => {
  try {
    let apiResponse = await axios.get(url, { responseType: "blob" });
    if (apiResponse?.data) downloadFile(apiResponse.data, fileName);
  } catch {
    console.log("Something went wrong.");
  }
};

export const getDate = (inputDate) => {
  if (inputDate instanceof Date) return inputDate;
  if (inputDate && typeof inputDate === "string") {
    let date = new Date(inputDate);
    if (!isNaN(date)) return date;
  }

  return null;
};

export const getPrevMonth = () => {
  let today = new Date();
  let month = today.getMonth();
  let prevMonth = month === 0 ? 11 : month - 3;
  return prevMonth;
};

export const checkIfDateIsInCurrentFinancialYear = (value) => {
  let inputDate = getDate(value);
  console.log(inputDate, "ReceiptDate or ReferenceDate");
  const today = new Date();
  let financialYearStartDate = new Date();
  let financialYearEndDate = new Date();

  if (today.getMonth() + 1 <= 3) {
    financialYearStartDate.setFullYear(today.getFullYear() - 1);
    financialYearEndDate.setFullYear(today.getFullYear());
  } else {
    financialYearStartDate.setFullYear(today.getFullYear());
    financialYearEndDate.setFullYear(today.getFullYear() + 1);
  }

  financialYearStartDate.setMonth(3);
  financialYearStartDate.setDate(1);
  financialYearStartDate.setHours(0, 0, 0, 0);

  financialYearEndDate.setMonth(2);
  financialYearEndDate.setDate(31);
  financialYearEndDate.setHours(23, 59, 59, 999);
  //Testing on 4-apr-2023
  //enterDate 28-mar-2023
  //FSD 1-apr-2023
  //FED 31-mar-2024

  // financialYearStartDate = new Date("2023-04-01");
  // financialYearEndDate = new Date("2024-03-31");
  console.log("financialYearStartDate---->", financialYearStartDate);
  console.log("financialYearEndDate---->", financialYearEndDate);
  if (
    inputDate >= financialYearStartDate &&
    inputDate <= financialYearEndDate
  ) {
    return true;
  }
  return false;
};
