// constants
import {
  ROLE_SA,
  ROLE_CT,
  ROLE_SG,
  ROLE_CC,
  ROLE_CFC,
  ROLE_DC,
  ROLE_SDC,
} from "utils/constants";

export default [
  {
    role: ROLE_SA,
    route: [
      "/",
      "country-treasurer",
      "session-dashboard",
      "standard-data",
      "standard-data/country",
      "standard-data/organization",
      "standard-data/role",
      "card-settlement",
      "team-dashboard",
    ],
  },
  {
    role: ROLE_CT,
    route: [
      "/",
      "donation-update",
      "session-dashboard",
      "center-coordinator",
      "donation-bulk-download",
      "standard-data",
      "standard-data/center",
      "standard-data/campaign",
      "standard-data/account",
      "card-settlement",
      "team-dashboard",
      "special-guest",
      "donor-profile",
      "abhyasi-info",
    ],
  },
  {
    role: ROLE_SG,
    route: [
      "/",
      // "donation-bulk-download",
      // "standard-data",
      // "standard-data/country",
      // "standard-data/organization",
      // "standard-data/center",
      // "standard-data/campaign",
      // "standard-data/account",
      // "standard-data/role",
      // "team-dashboard",
      "view-donation",
      "session-dashboard",
      "team-dashboard",
    ],
  },
  {
    role: ROLE_CC,
    route: [
      "/",
      "view-donation",
      "session-dashboard",
      "center-finance-coordinator",
      "donation-collector",
      "donation-bulk-download",
      "card-settlement",
      "team-dashboard",
    ],
  },
  {
    role: ROLE_CFC,
    route: [
      "/",
      "confirm-session",
      "deposit-donation",
      "deposit-donation-summary",
      "upload-challan",
      "donation-collector",
      "view-donation",
      "donation-bulk-download",
      "card-settlement",
      "team-dashboard",
      "donor-profile",
      "submit-dc-session",
    ],
  },
  {
    role: ROLE_DC,
    route: [
      "/",
      "donation",
      "view-donation",
      "submit-session",
      "/session-summary/:id",
      "card-settlement",
      "team-dashboard",
      "donor-profile",
      "mobile-thermal-view",
    ],
  },
  {
    role: ROLE_SDC,
    route: [
      "/",
      "donation",
      "view-donation",
      "submit-session",
      "/session-summary/:id",
      "card-settlement",
      "team-dashboard",
      "donor-profile",
    ],
  },
];
