// messages
const validationMessage = {
  required: "This field is required*",
  email: "Please enter a valid email id",
  phoneNo: "Please enter a valid Contact Number",
  pinCode: "Please enter a valid Pin Code",
  numberText: "Please enter a number",
  abhyasiIdPattern: "Please enter a valid Abhyasi ID",
  panNoPattern: "Please enter the valid PAN Number",
  tanNoPattern: "Please enter a valid TAN No",
  gstNoPattern: "Please enter a valid GST No",
  aadharPattern: "Please enter a valid Aadhar No",
  dlPattern: "Please enter a valid License No",
  ifscCode: "Please enter a valid IFSC Code",
  sessionId: "Please enter a number",
  alphaNumeric: "Please enter valid Settlement Number",
};

// validations
const validations = {
  required: {
    value: true,
    message: validationMessage.required,
  },

  email: {
    value: /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,3}|museum)$/,
    message: validationMessage.email,
  },

  alphaNumeric: {
    value: /^\w+$/,
    message: validationMessage.alphaNumeric,
  },

  sessionId: {
    value: /^[0-9,]*$/,
    message: validationMessage.sessionId,
  },

  phoneNo: {
    value: /^[0-9]{10,14}$/,
    message: validationMessage.phoneNo,
  },

  pinCode: {
    value: /^[1-9]{1}[0-9]{2}\s?[0-9]{3}$/,
    message: validationMessage.pinCode,
  },

  ifscCode: {
    value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    message: validationMessage.ifscCode,
  },

  numberText: {
    value: /^\d+$/,
    message: validationMessage.numberText,
  },

  abhyasiId: {
    value: /^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$/,
    message: validationMessage.abhyasiIdPattern,
  },

  panNo: {
    value: /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
    message: validationMessage.panNoPattern,
  },

  tanNo: {
    value: /^([A-Z]{4}[0-9]{5}[A-Z]{1})$/,
    message: validationMessage.tanNoPattern,
  },

  gstNo: {
    value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    message: validationMessage.gstNoPattern,
  },

  aadhar: {
    value: /^[0-9]{12}$/,
    message: validationMessage.aadharPattern,
  },

  dl: {
    value:
      /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2})|([A-Z]{2}[0-9]{2}[A-Z]{1}))((19|20)[0-9][0-9])[0-9]{7}$/,
    message: validationMessage.dlPattern,
  },
};

export default validations;
