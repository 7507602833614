//import { ROLE_SA as ROLE } from "utils/constants";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";

// router
import { useNavigate } from "react-router-dom";

// components
import {
  HfnFirebaseAuth,
  signOut,
} from "@heartfulnessinstitute/react-hfn-profile";

import LoginPanel from "components/login/LoginPanel";

// prime components
import { Card } from "primereact/card";

// shared components
import HFNModalPopup from "sharedComponents/modalPopup";

// utils
import { getLoginRoles, isLoginAuth } from "utils/login";

import { lStorage } from "utils/storage";

import toaster from "utils/toaster";

import { getResponseMessage, getHomePath } from "utils/common";

import modalPopup from "utils/modalPopup";

// services
import LoginService from "services/login.service";
import { ROLE_DC } from "utils/constants";

const Login = () => {
  let navigate = useNavigate();

  const [loginCheck, setLoginCheck] = useState(false);

  const [loginUserRoles, setLoginUserRoles] = useState({});

  useEffect(() => {
    if (isLoginAuth()) navigate(getHomePath());
    else signOut();
  }, []);

  const firebaseRef = useRef(null);

  const hasAPICall = useRef(false);

  const loginService = useMemo(() => new LoginService(), []);

  const getLoginUser = useCallback(async (srcmToken) => {
    try {
      lStorage.remove("authInfo");
      let roles = {};
      let roleResponse = await loginService.getRoles({ srcmToken });

      if (
        !Array.isArray(roleResponse?.data?.results) ||
        roleResponse.data.results.length === 0
      )
        throw Error("Unable to get roles");
      else {
        const roleList = roleResponse.data.results;
        roles = Object.fromEntries(
          roleList.map((item) => [item.roleName, item.id])
        );
      }

      let apiResponse = await loginService.getCurrentUser({ srcmToken });
      if (apiResponse && apiResponse.data) {
        if (!apiResponse.data.isError) {
          let user = { ...apiResponse.data, srcmToken };
          const loginUser = getLoginRoles(apiResponse.data);
          if (loginUser.isValidUser && !loginUser.hasMoreRoles) {
            user.roles = roles;
            user.loginUser = loginUser.loginUser;
            user.loginRole = loginUser.loginRole;
            user.loginRoles = loginUser.loginRoles;
            user.hasMoreRoles = loginUser.hasMoreRoles;

            try {
              await loginService.getLoginEnvironment(user);
              await loginService.setLoginEnvironment(user);
            } catch {
              console.log("Something went wrong.");
            }

            lStorage.set("authInfo", user);
            if (
              loginUser.loginRole &&
              loginUser.loginRole.roleName === ROLE_DC
            ) {
              setLoginUserRoles({ loginUser, user, roles });
              modalPopup.custom({
                header: "Select Your Role",
                className: "login-popup",
                visible: true,
                draggable: false,
              });
            } else {
              navigate(getHomePath());
            }
          } else {
            setLoginUserRoles({ loginUser, user, roles });
            modalPopup.custom({
              header: "Select Your Role",
              className: "login-popup",
              visible: true,
              draggable: false,
            });
          }
        } else {
          toaster.error(
            getResponseMessage(apiResponse.data) ||
              "Unable to login. Please contact your administrator."
          );
          hasAPICall.current = false;
          setLoginCheck(false);
          signOut();
        }
      } else {
        hasAPICall.current = false;
        setLoginCheck(false);
        signOut();
      }
    } catch {
      console.log("Something went wrong.");
      hasAPICall.current = false;
      setLoginCheck(false);
      signOut();
    }
  }, []);

  const processLogin = useCallback(({ myInfo }) => {
    if (myInfo && firebaseRef.current) {
      firebaseRef.current.authFn.onAuthStateChanged((firebaseUser) => {
        firebaseUser?.getIdToken().then((srcmToken) => {
          if (!hasAPICall.current && myInfo.id) {
            hasAPICall.current = true;
            setLoginCheck(true);
            getLoginUser(srcmToken);
          }
        });
      });
    }
  }, []);

  const closePanel = useCallback(() => {
    lStorage.clear();
    signOut();
    hasAPICall.current = false;
    setLoginCheck(false);
    modalPopup.toggle(false);
  }, []);

  return (
    <div className="login-section">
      <div className="card-wrapper">
        <div className="loging-logo p-px-2 p-mx-5 p-mx-md-0 p-mb-4 p-text-center">
          <img src="/assets/logo.png" alt="heartfulness" />
        </div>

        <Card>
          <div className="login">
            <h2 className="title"> LogIn</h2>
            <HfnFirebaseAuth
              ref={firebaseRef}
              titleText=""
              doLogin={processLogin}
            />
            {loginCheck ? (
              <div className="p-text-center p-m-4 p-text-bold">
                {" "}
                Fetching info...{" "}
              </div>
            ) : null}
          </div>
        </Card>
      </div>
      {loginUserRoles.loginUser ? (
        <HFNModalPopup>
          <LoginPanel
            closePanel={closePanel}
            roleList={loginUserRoles.roles}
            loginUserRoles={loginUserRoles.loginUser}
            user={loginUserRoles.user}
            isLogin={true}
          />
        </HFNModalPopup>
      ) : null}
    </div>
  );
};

export default Login;
