import React, { useCallback } from "react";

// router
import { useNavigate } from "react-router-dom";

// prime components
import { Button } from "primereact/button";

// utils
import { getHomePath } from "utils/common";

const HomeButton = ({ clearError }) => {
  const navigate = useNavigate();

  const goToDashboard = useCallback(() => {
    clearError();
    navigate(getHomePath());
  }, []);

  return (
    <Button label="Go To Home" className="goto-home-button p-mt-2 p-mr-3" onClick={goToDashboard} />
  )
}

export default HomeButton;
