// state
import appStore from "store";

import { APP_FIREBASEUSER } from "store/actionTypes/app";

import { RESET, LOGOUT } from "store/actionTypes/login";

// utils
import { signOut } from "@heartfulnessinstitute/react-hfn-profile";

import { lStorage } from "utils/storage";

// constants
import {
  ROLE_SA,
  ROLE_CT,
  ROLE_SG,
  ROLE_CC,
  ROLE_CFC,
  ROLE_DC,
  ROLE_SDC,
  generalStatuses,
} from "utils/constants";

export const getLoginRoles = (data) => {
  if (Array.isArray(data.adminUserRoleMap)) {
    const userRoles = data.adminUserRoleMap;
    let roles = {
      roles: [],
      sa: {},
      ct: {
        countries: [],
        organizations: [],
      },
      sg: {
        countries: [],
        organizations: [],
        centers: [],
      },
      cc: {
        countries: [],
        organizations: [],
        centers: [],
      },
      cfc: {
        countries: [],
        organizations: [],
        centers: [],
      },
      dc: {
        countries: [],
        organizations: [],
        centers: [],
      },
      sdc: {
        countries: [],
        organizations: [],
        centers: [],
      },
    };
    let isValidUser = true,
      hasMoreRoles = true,
      loginRole,
      loginUser;
    try {
      userRoles.forEach((item) => {
        if (
          item.role?.roleStatus === generalStatuses.active.value &&
          item.status === generalStatuses.active.value &&
          item.adminUser?.status === generalStatuses.active.value
        ) {
          if (item.role.roleName === ROLE_SA) {
            if (!roles.roles.find((value) => value.id === item.role.id)) {
              roles.roles.push({
                ...item.role,
                label: item.role.displayName,
                value: item.role.id,
              });
              roles.sa = { ...item.adminUser, userRoleId: item.id };
            }
          } else if (item.role.roleName === ROLE_CT) {
            if (item.country.countryStatus === generalStatuses.active.value) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.ct.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.ct.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.ct.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.ct.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          } else if (item.role.roleName === ROLE_SG) {
            if (
              item.country.countryStatus === generalStatuses.active.value &&
              item.center.centerStatus === generalStatuses.active.value
            ) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.sg.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.sg.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.sg.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.sg.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                });
              if (
                !roles.sg.centers.find((value) => value.id === item.center.id)
              )
                roles.sg.centers.push({
                  ...item.center,
                  label:
                    item.center.centerName +
                    (item.center.city ? ", " + item.center.city : ""),
                  value: item.center.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          } else if (item.role.roleName === ROLE_CC) {
            if (
              item.country.countryStatus === generalStatuses.active.value &&
              item.center.centerStatus === generalStatuses.active.value
            ) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.cc.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.cc.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.cc.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.cc.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                });
              if (
                !roles.cc.centers.find((value) => value.id === item.center.id)
              )
                roles.cc.centers.push({
                  ...item.center,
                  label:
                    item.center.centerName +
                    (item.center.city ? ", " + item.center.city : ""),
                  value: item.center.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          } else if (item.role.roleName === ROLE_CFC) {
            if (
              item.country.countryStatus === generalStatuses.active.value &&
              item.center.centerStatus === generalStatuses.active.value
            ) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.cfc.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.cfc.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.cfc.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.cfc.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                });
              if (
                !roles.cfc.centers.find((value) => value.id === item.center.id)
              )
                roles.cfc.centers.push({
                  ...item.center,
                  label:
                    item.center.centerName +
                    (item.center.city ? ", " + item.center.city : ""),
                  value: item.center.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          } else if (item.role.roleName === ROLE_DC) {
            if (
              item.country.countryStatus === generalStatuses.active.value &&
              item.center.centerStatus === generalStatuses.active.value
            ) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.dc.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.dc.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.dc.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.dc.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                });
              if (
                !roles.dc.centers.find((value) => value.id === item.center.id)
              )
                roles.dc.centers.push({
                  ...item.center,
                  label:
                    item.center.centerName +
                    (item.center.city ? ", " + item.center.city : ""),
                  value: item.center.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          } else if (item.role.roleName === ROLE_SDC) {
            if (
              item.country.countryStatus === generalStatuses.active.value &&
              item.center.centerStatus === generalStatuses.active.value
            ) {
              if (!roles.roles.find((value) => value.id === item.role.id))
                roles.roles.push({
                  ...item.role,
                  label: item.role.displayName,
                  value: item.role.id,
                });
              if (
                !roles.sdc.countries.find(
                  (value) => value.id === item.country.id
                )
              )
                roles.sdc.countries.push({
                  ...item.country,
                  label: item.country.countryName,
                  value: item.country.id,
                });
              if (
                !roles.sdc.organizations.find(
                  (value) => value.id === item.organization.id
                )
              )
                roles.sdc.organizations.push({
                  ...item.organization,
                  label: item.organization.orgName,
                  value: item.organization.id,
                });
              if (
                !roles.sdc.centers.find((value) => value.id === item.center.id)
              )
                roles.sdc.centers.push({
                  ...item.center,
                  label:
                    item.center.centerName +
                    (item.center.city ? ", " + item.center.city : ""),
                  value: item.center.id,
                  adminUser: { ...item.adminUser, userRoleId: item.id },
                });
            }
          }
        }
      });
      if (roles.roles.length === 0) isValidUser = false;

      if (roles.roles.length === 1) {
        const roleName = roles.roles[0].roleName;

        loginRole = roles.roles[0];
        if (roleName === ROLE_SA) {
          loginUser = roles.sa;
          hasMoreRoles = false;
        }
        if (roleName === ROLE_CT && roles.ct.organizations.length === 1) {
          loginUser = {
            ...roles.ct.organizations[0].adminUser,
            country: roles.ct.countries[0],
            organization: roles.ct.organizations[0],
          };
          hasMoreRoles = false;
        }
        if (roleName === ROLE_SG && roles.sg.centers.length === 1) {
          loginUser = {
            ...roles.sg.centers[0].adminUser,
            country: roles.sg.countries[0],
            organization: roles.sg.organizations[0],
            center: roles.sg.centers[0],
          };
          hasMoreRoles = false;
        }
        if (roleName === ROLE_CC && roles.cc.centers.length === 1) {
          loginUser = {
            ...roles.cc.centers[0].adminUser,
            country: roles.cc.countries[0],
            organization: roles.cc.organizations[0],
            center: roles.cc.centers[0],
          };
          hasMoreRoles = false;
        }
        if (roleName === ROLE_CFC && roles.cfc.centers.length === 1) {
          loginUser = {
            ...roles.cfc.centers[0].adminUser,
            country: roles.cfc.countries[0],
            organization: roles.cfc.organizations[0],
            center: roles.cfc.centers[0],
          };
          hasMoreRoles = false;
        }
        if (roleName === ROLE_DC && roles.dc.centers.length === 1) {
          loginUser = {
            ...roles.dc.centers[0].adminUser,
            country: roles.dc.countries[0],
            organization: roles.dc.organizations[0],
            center: roles.dc.centers[0],
          };
          hasMoreRoles = false;
        }
        if (roleName === ROLE_SDC && roles.sdc.centers.length === 1) {
          loginUser = {
            ...roles.sdc.centers[0].adminUser,
            country: roles.sdc.countries[0],
            organization: roles.sdc.organizations[0],
            center: roles.sdc.centers[0],
          };
          hasMoreRoles = false;
        }
      }
    } catch {
      return {};
    }
    return {
      isValidUser,
      loginRoles: roles,
      hasMoreRoles,
      loginRole,
      loginUser,
    };
  }
};

export const getLoginID = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.id || null;
};

export const getRoleIds = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.roles || {};
};

export const getLoginName = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.name || "";
};

export const getLoginRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginRole || {};
};

export const getLoginUserRole = () => {
  const loginDetails = lStorage.get("authInfo");
  return loginDetails?.loginUser || {};
};

export const getLoginUser = () => {
  const loginDetails = lStorage.get("authInfo");

  return loginDetails || {};
};

export const setLoginUser = (payload) => {
  lStorage.set("authInfo", payload);
};

export const isLoginAuth = () => {
  let loginDetails = lStorage.get("authInfo");
  return !!(
    loginDetails?.srcmToken &&
    loginDetails.firebaseId &&
    loginDetails.loginUser
  );
};

export const logout = (showExpired = true) => {
  lStorage.clear();
  signOut();
  if (showExpired) appStore.dispatch({ type: LOGOUT });
};

export const resetState = () => {
  appStore.dispatch({ type: RESET });
};

export const setFirebaseUser = (payload) => {
  appStore.dispatch({ type: APP_FIREBASEUSER, payload });
};

export const getFirebaseUser = () => {
  return appStore.getState().appDetails.firebaseUser;
};

export const setFirebaseToken = (srcmToken) => {
  const loginDetails = lStorage.get("authInfo");
  if (loginDetails?.srcmToken && loginDetails.srcmToken !== srcmToken)
    lStorage.set("authInfo", { ...loginDetails, srcmToken });
};