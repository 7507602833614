import {
  CLEAR,
  COUNTRY,
  ORGANIZATION,
  COUNTRYORG,
  CENTER,
  ORGCENTER,
  CAMPAIGN,
  ORGCAMPAIGN,
  ROLE,
  USER,
  STATICCOUNTRY,
  STATICCURRENCY,
  TIMEZONES,
} from "store/actionTypes/dropdown";

import { RESET } from "store/actionTypes/login";

// constants
import {
  generalStatuses,
  bankAccStatuses,
  sessionStatuses,
  batchStatuses,
  idTypes,
  paymentModes,
  citizenshipFilters,
  accountTypes,
  donationStatuses,
  centerTypes,
  cardSettlementStatuses,
  passportOptionses,
  depositPaymentModes,
  printerOptionses,
} from "utils/constants";

const initialState = {
  depositPayment: Object.entries(depositPaymentModes).map((item) => item[1]),
  generalStatus: Object.entries(generalStatuses).map((item) => item[1]),
  passportOptions: Object.entries(passportOptionses).map((item) => item[1]),
  printerOptions: Object.entries(printerOptionses).map((item) => item[1]),
  bankAccStatus: Object.entries(bankAccStatuses).map((item) => item[1]),
  sessionStatus: Object.values(sessionStatuses),
  donationStatus: Object.values(donationStatuses),
  batchStatus: Object.values(batchStatuses),
  cardSettlementStatus: Object.values(cardSettlementStatuses),
  country: [],
  organization: [],
  countryOrg: [],
  center: [],
  orgCenter: [],
  campaign: [],
  orgCampaign: [],
  idType: Object.entries(idTypes).map((item) => item[1]),
  paymentMode: Object.entries(paymentModes).map((item) => item[1]),
  citizenshipFilter: Object.entries(citizenshipFilters).map((item) => item[1]),
  accountType: Object.entries(accountTypes).map((item) => item[1]),
  centerType: Object.entries(centerTypes).map((item) => item[1]),
  role: [],
  user: [],
  staticCountry: [],
  staticCurrency: [],
  timeZones: [],
};

// reducer
const dropdownDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...state, ...initialState };
    case CLEAR:
      return { ...state, ...initialState };
    case COUNTRY:
      return { ...state, country: action.payload };
    case ORGANIZATION:
      return { ...state, organization: action.payload };
    case COUNTRYORG:
      return { ...state, countryOrg: action.payload };
    case CENTER:
      return { ...state, center: action.payload };
    case ORGCENTER:
      return { ...state, orgCenter: action.payload };
    case CAMPAIGN:
      return { ...state, campaign: action.payload };
    case ORGCAMPAIGN:
      return { ...state, orgCampaign: action.payload };
    case ROLE:
      return { ...state, role: action.payload };
    case USER:
      return { ...state, user: action.payload };
    case STATICCOUNTRY:
      return { ...state, staticCountry: action.payload };
    case STATICCURRENCY:
      return { ...state, staticCurrency: action.payload };
    case TIMEZONES:
      return { ...state, timeZones: action.payload };
    default:
      return state;
  }
};

export default dropdownDetails;
