// store
import appStore from "store";

import { TOGGLE, ACCEPT, REJECT, ONHIDE, CUSTOM } from "store/actionTypes/confirmDialog";

import { RESET } from "store/actionTypes/login";

// constants
const initialState = {
  message: "Are you sure you want to proceed?",
  header: "Confirmation",
  icon: "pi pi-exclamation-triangle",
  visible: false,
  onHide: () => {
    appStore.dispatch({ type: TOGGLE, payload: false });
  }
};

// reducer
const confirmDialogDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return { ...state, ...initialState };
    case TOGGLE:
      return { ...initialState, visible: action.payload };
    case ACCEPT:
      return { ...state, accept: action.payload };
    case REJECT:
      return { ...state, reject: action.payload };
    case ONHIDE:
      return {
        ...state,
        onHide: () => {
          appStore.dispatch({ type: "TOGGLE", payload: false });
          action.payload()
        }
      };
    case CUSTOM:
      return { ...initialState, ...action.payload };
    default:
      return state;
  }
};

export default confirmDialogDetails;
