import React from "react";
import ReactDOM from "react-dom/client";

// router
import { BrowserRouter as Router } from "react-router-dom";

// state
import { Provider } from "react-redux";

import appStore from "store";

// components
import App from "App";

// styles
import "index.scss";
import "font-awesome/css/font-awesome.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={appStore}>
    <Router>
      <App />
    </Router>
  </Provider>
);
