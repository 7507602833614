// services
import DropdownService from "services/dropdown/dropdown.service";

export const getSateCitySuggestions = async (
  inputValue,
  callback,
  country = "",
  state = ""
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    let countryName;
    let countryFilterValue;
    if (country !== null) {
      if (country.name || country.value) {
        if (country.label !== "") countryName = country.label.toLowerCase();
        else countryName = country.name.toLowerCase();
        countryFilterValue = `${countryName}/${filterValue[0]}`;
      } else {
        countryFilterValue = filterValue[0];
      }
    } else {
      countryFilterValue = filterValue[0];
    }

    try {
      apiResponse = await dropdownService.getCachedCitySuggestions(
        countryFilterValue
      );
      let results = apiResponse.data.results;
      results = results.filter(
        (item) => item.state === state.name || item.state === state.value
      );
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {
      try {
        console.log("Something went wong.");
      } catch {
        console.log("Something went wong.");
      }
    }
  }

  callback(suggestions || []);
};

export const getCitySuggestions = async (
  inputValue,
  callback,
  country = ""
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    let countryName;
    let countryFilterValue;
    if (country !== null) {
      if (country.name) {
        countryName = country.name.toLowerCase();
        countryFilterValue = `${countryName}/${filterValue[0]}`;
      } else {
        countryFilterValue = filterValue[0];
      }
    } else {
      countryFilterValue = filterValue[0];
    }

    try {
      apiResponse = await dropdownService.getCachedCitySuggestions(
        countryFilterValue
      );
      let results1 = apiResponse.data.results;
      let results2 = apiResponse.data.results;
      results1 = results1.filter((item) => item.country === country.name);
      for (const item of results1) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
      for (const item of results2) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {
      try {
        console.log("Something went wong.");
      } catch {
        console.log("Something went wong.");
      }
    }
  }

  callback(suggestions || []);
};

export const getStateSuggestions = async (
  inputValue,
  callback,
  country = ""
) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    let countryName;
    let countryFilterValue;
    if (country !== null) {
      if (country.name || country.value) {
        if (country.label !== "") countryName = country.label.toLowerCase();
        else countryName = country.name.toLowerCase();
        countryFilterValue = `${countryName}/${filterValue[0]}`;
      } else {
        countryFilterValue = filterValue[0];
      }
    } else {
      countryFilterValue = filterValue[0];
    }

    try {
      apiResponse = await dropdownService.getCachedStateSuggestions(
        countryFilterValue
      );
      let results = apiResponse.data.results;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }
    } catch {
      try {
        console.log("Something went wong.");
      } catch {
        console.log("Something went wong.");
      }
    }
  }

  callback(suggestions || []);
};

export const getCountrySuggestions = async (inputValue, callback) => {
  const filterValue = inputValue ? inputValue.trim().toLowerCase() : null;
  let suggestions = [];

  if (filterValue) {
    const dropdownService = new DropdownService();
    let apiResponse;

    try {
      apiResponse = await dropdownService.getCachedCountrySuggestions(
        filterValue[0]
      );
      let results = apiResponse.data.results;
      for (const item of results) {
        if (item.active && item.name.toLowerCase().startsWith(filterValue)) {
          suggestions.push({ ...item, value: item.id, label: item.name });
          if (suggestions.length === 20) break;
        }
      }

      if (suggestions.length === 0) {
        try {
          apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
          suggestions = apiResponse.data.map((item) => ({
            ...item,
            value: item.country_id,
            label: item.country,
          }));
        } catch {
          console.log("Something went wong.");
        }
      }
    } catch {
      try {
        apiResponse = await dropdownService.getGPlaceSuggestions(filterValue);
        suggestions = apiResponse.data.map((item) => ({
          ...item,
          value: item.country_id,
          label: item.country,
        }));
      } catch {
        console.log("Something went wong.");
      }
    }
  }

  callback(suggestions || []);
};
